import React, { useCallback, useState,useEffect  } from "react";
import { PieChart, Sector,Pie, Cell,Tooltip,ResponsiveContainer } from "recharts";
import axios from 'axios'


const COLORS = ["#737373","#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderActiveShape = (props) => {
  //console.log('nmbrdossier ',nmbrdossier);

  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,

  } = props;

  




  return (
    <g>
     
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
     
     
     
    </g>
  );
};
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.52;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function Piepercentage(props) {

  const [Data, setData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      if(props.pref){
        var valeur = props.pref
        if(valeur.includes("'")){
            valeur = valeur.replace(/'/g, "''");
        }
        if(props.com){
          var valeur1 = props.com
          if(valeur1.includes("'")){
              valeur1 = valeur1.replace(/'/g, "''");
          }
          var comfapi;
          if(props.annee){
            comfapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ARRONDISSEMENT"='`+valeur1+`'AND "ANNEE"='`+props.annee+`'`;
          }else{
            comfapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ARRONDISSEMENT"='`+valeur1+`'`;
          }
          await axios.get(comfapi, {
            "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
         console.log(response.data.data)
            setData(response.data.data)
        })
  
        }else{
          var prefapi;
          if(props.annee){
            prefapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="prefecture"='`+valeur+`'AND "ANNEE"='`+props.annee+`'`;
          }else{
            prefapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="prefecture"='`+valeur+`'`;
          }
          await axios.get(prefapi, {
            "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
         console.log(response.data.data)
            setData(response.data.data)
        })
    
        }
     
  
      }
      
      
      else{
        var apiel;
        if(props.annee){
          apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ANNEE"='`+props.annee+`'`
        }else{
          apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Distribution des permis de construire délivrés par type de projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&kanalytics_OUTPUT_FORMAT=JSON&ZOHO_OUTPUT_FORMAT=JSON`
        }
        await axios.get(apiel, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
       console.log(response.data.data)
          setData(response.data.data)
      })
      }
       
    };
      getData();
  }, [props.pref,props.com,props.annee]);


 
  var data=[];
if(Data.length !==0){
  console.log('Dataaa',Data)
  for (let i = 0; i < Data.length; i++) {
    let typeDesc = Data[i].TYPE_DESC;
    let distinctCount = parseInt(Data[i]['DOSSIER_ID Nombre distinct']);
    data.push({ name: typeDesc, value: distinctCount });
}
}else{
   data = [
    { name: "", value: 0 },
    { name: "", value: 0 },
    { name: "", value: 0 },
    { name: "", value: 0 }
  ];
}

  

  const [activeIndex, setActiveIndex] = useState(0);


  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index)
    
    },
    [setActiveIndex]
  );
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      console.log('payload',payload);
      var day=payload[0].name
      var colorr;
      switch (day) {
        case 'Immeuble moins de 15.5 m à usage habitation':
          colorr = "#BAE0FA";
          break;
        case 'Villa ou immeuble moins de 11.5 m dans un lotissement réceptionné':
          colorr = "#BAE0FA";
          break;
          case "Surélévation d'une construction vers une hauteur inférieure ou égale à 15,5 m":
            colorr = "#BAE0FA";
            break;
            case 'Modification d’une construction inférieure ou égale 15,5 m':
              colorr = "#BAE0FA";
              break;
        default:
          colorr = "#BAE0FA";
      }



var bord='1px solid '+colorr
console.log(bord);


      return (
        
        <div className="custom-tooltip" style={{width:'200px',height:'170px',backgroundColor:'#EEEEEE',top:'40px',zIndex:'200000000',opacity:'0.9',borderRight:bord,borderBottom:bord,borderLeft:bord,marginBottom:'20px'}}>
          <div style={{width:'100%',height:'4px',backgroundColor:colorr}}></div>
          <div  style={{display:'flex',flexDirection:'row',width:'100%',margin:'8px'}}>
            <div>
              <span>
                TYPE : 
              </span>
            </div>
            <div style={{width:'150px',wordWrap: 'break-word',paddingLeft:'4px'}}>
            <span> {`  ${payload[0].name?payload[0].name:'Autre'}`}</span>
            </div>
         
          </div>

          <div  style={{display:'flex',flexDirection:'row',width:'100%',margin:'8px'}}>
            <div>
              <span>
                nomber dossiers : 
              </span>
            </div>
            <div style={{wordWrap: 'break-word',paddingLeft:'4px'}}>
            <span> {`  ${payload[0].name?payload[0].value:''}`}</span>
            </div>
         
          </div>
         
        </div>
      );
    }
    return null;
  };


































  return (
    <ResponsiveContainer width={350} height={280} >
    <PieChart >
      <Pie
       activeIndex={activeIndex}
       activeShape={(props) => renderActiveShape(props)}
        data={data}
        cx={130}
        cy={120}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        onMouseEnter={onPieEnter}
      >
        {data?.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={<CustomTooltip />} />
    </PieChart>
    </ResponsiveContainer>
  )
}
