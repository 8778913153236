import React, { useEffect,useState } from 'react';
import 'antd/dist/antd.min.css';
import '../components/Style.css';
import HomeLayout from "../components/HomeLayout";
import MyHead from "../components/head";
import DescriptionH from '../components/DescriptionHorizontal';
import DescriptionHH from '../components/DescriptionHorizontall';
//import Iframe from 'react-iframe';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import IndecateurglobalEco from './IndecateurglobalEco';
import { ReactComponent as Arrow1 } from '../components/svgs_img/arrow-down-solid.svg';
import { ReactComponent as Arrow } from '../components/svgs_img/caret-up-solid.svg';


const IndicateursGlobaux = () => {
    const navigate = useNavigate();
    const [DemoMonthStat, setDemoMonthStat] = useState("");
    const [receMonthStat, setreceMonthStat] = useState("");
    const [refeMonthStat, setrefeMonthStat] = useState("");
    const [PCMonthStat, setPCMonthStat] = useState("");
    const [PHMonthStat, setPHMonthStat] = useState("");
    const [ReguMonthStat, setReguMonthStat] = useState("");

    const [DemoMonthpré, setDemoMonthpré] = useState("");
    const [receMonthpré, setreceMonthpré] = useState("");
    const [refeMonthpré, setrefeMonthpré] = useState("");
    const [PCMonthpré, setPCMonthpré] = useState("");
    const [PHMonthpré, setPHMonthpré] = useState("");
    const [REGMonthpré, setREGMonthpré] = useState("");

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentDate(new Date());
      }, 60000); // Update every minute (you can adjust the interval as needed)
  
      return () => {
        clearInterval(intervalId); // Clear the interval on component unmount
      };
    }, []);
  
    const currentMonthName = currentDate.toLocaleDateString('fr-FR', { month: 'long' });
    const previousMonthDate = new Date(currentDate);
    previousMonthDate.setMonth(currentDate.getMonth() - 1);
    //const previousMonthName = previousMonthDate.toLocaleDateString('fr-FR', { month: 'long' });
    const year = currentDate.getFullYear();
  
  
   // const previousMonthPhrase = ` Mois ${previousMonthName}`;
    

    
    useEffect(() => {
        const Data2 = async () => {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Ce mois-ci DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setDemoMonthStat(somme)
              })
        };

        const monthstatrec =async ()=>{
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Ce mois-ci RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setreceMonthStat(somme)
              })
        }
        const monthstatref =async ()=>{
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Ce mois-ci RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setrefeMonthStat(somme)
              })
        }

        const monthstatPC =async ()=>{
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/pc ce mois-ci?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setPCMonthStat(somme)
              })
        }

        const monthstatPH =async ()=>{
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/PH ce mois-ci?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setPHMonthStat(somme)
              })
        }

        const monthstatReg =async ()=>{
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Ce mois-ci REG?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
              }).then((response) => {
                console.log(response.data.data)
                var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
                var somme = 0
                for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setReguMonthStat(somme)
              })
        }





//month précedent

const DatapréDemol = async () => {
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Mois précédent DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
        "Content-Type": "application/json; charset=utf-8"
    }).then((response) => {
     console.log(response.data.data)
     var l=response.data.data.map(x=>parseInt(x['DOSSIER_ID Count']))
     var somme =0
     for (let i = 0; i < l.length; i++) {
      somme += l[i];
    }
    setDemoMonthpré(somme)
    })
};

const monthprerec =async ()=>{
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Mois precedent RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
        "Content-Type": "application/json; charset=utf-8"
    }).then((response) => {
        console.log(response.data.data)
        var l = response.data.data.map(x => parseInt(x['DOSSIER_ID Count']))
        var somme = 0
        for (let i = 0; i < l.length; i++) {
            somme += l[i];
        }
        setreceMonthpré(somme)
    })
}
const monthspréref =async ()=>{
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Mois precedent RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                 var l=response.data.data.map(x=>parseInt(x['DOSSIER_ID Count']))
                 var somme =0
                 for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setrefeMonthpré(somme)
                })
}

const monthsprépc =async ()=>{
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/pc mois précedent?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                 var l=response.data.data.map(x=>parseInt(x['DOSSIER_ID Count']))
                 var somme =0
                 for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setPCMonthpré(somme)
                })
}

const monthspréph =async ()=>{
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/PH mois précedent?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                 var l=response.data.data.map(x=>parseInt(x['DOSSIER_ID Count']))
                 var somme =0
                 for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setPHMonthpré(somme)
                })
}
const monthspréREG =async ()=>{
    await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/Mois précédent REG?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                 var l=response.data.data.map(x=>parseInt(x['DOSSIER_ID Count']))
                 var somme =0
                 for (let i = 0; i < l.length; i++) {
                  somme += l[i];
                }
                setREGMonthpré(somme)
                })
}




        Data2();
        monthstatrec();
        monthstatref();
        monthstatPC();
        monthstatPH();
        monthstatReg();


        DatapréDemol();
        monthprerec();
        monthspréref();
        monthsprépc();
        monthspréph();
        monthspréREG();
    }, []);
   
   

    const handleClick = () => {
        // This function will be executed when the div is clicked
        navigate('/OpenData/PermisConstruire');
        // You can perform any other actions here
    };
    const handleClick1 = () => {
        
        navigate('/OpenData/Reception');
     
    };
    const handleClick2 = () => {
        navigate('/OpenData/Demolition');
    };
    const handleClick3 = () => {
      
        navigate('/OpenData/PermisHabiter');

    };
    const handleClick4 = () => {
      
        navigate('/OpenData/Refection');
       
    };
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
   
 
    return (
        <>
            <HomeLayout>

                <MyHead text="urbanistiques et économiques délivrées à l’échelle nationale" title="Données ouvertes des autorisations au Maroc" />
               
                <DescriptionH  text={`Autorisation urbanistiques délivrées en ${currentMonthName}  ${year}`}/>
                <div class="indica">
                <div class="indicateur" >
                    <div class='rounded cursor-pointer donneesOvert' onClick={handleClick} style={{backgroundColor: '#1C5182' }}>
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '23px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Permis de construire</span>
                        </div>
                        <div>
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{PCMonthStat<PCMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {PCMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {PCMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>


                        )}</span>
                        </div>
                        <div>
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {PCMonthpré}</span>
                        </div>
                    </div>


                    <div class='rounded cursor-pointer donneesOvert' onClick={handleClick1} style={{backgroundColor: '#52A352' }} >
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Réception</span>
                        </div>
                        <div >
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{receMonthStat<receMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {receMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {receMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>


                        )}</span>
                        </div>
                        <div >
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {receMonthpré}</span>
                        </div>
                    </div>


                    <div class='rounded cursor-pointer donneesOvert' onClick={handleClick2} style={{backgroundColor: '#5B98E3' }} >
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Démolition</span>
                        </div>
                        <div >
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{DemoMonthStat<DemoMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {DemoMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {DemoMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                            </div>


                        )}</span>
                        </div>
                        <div >
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {DemoMonthpré}</span>
                        </div>
                    </div>


                    <div class='rounded cursor-pointer donneesOvert' onClick={handleClick3} style={{backgroundColor: '#2A9729' }} >
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Permis d'habiter </span>
                        </div>
                        <div >
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{PHMonthStat<PHMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {PHMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {PHMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>


                        )}</span>
                        </div>
                        <div >
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {PHMonthpré}</span>
                        </div>
                    </div>


                    <div class='rounded cursor-pointer donneesOvert' onClick={handleClick4} style={{backgroundColor: '#16A0DD' }} >
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Réfection</span>
                        </div>
                        <div >
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{refeMonthStat<refeMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {refeMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {refeMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                            </div>


                        )}</span>
                        </div>
                        <div >
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {refeMonthpré}</span>
                        </div>
                    </div>
                    <div class='rounded cursor-pointer donneesOvert'  style={{backgroundColor: '#7BBFE7' }} >
                        <div >
                            <span style={{fontFamily: 'Arial', fontSize: '24px', fontWeight: 'bold', lineHeight: '24px',color:'white'}}>Régularisation</span>
                        </div>
                        <div >
                            <span class='normal text-5xl font-extrabold'  style={{color:'white'}}>{ReguMonthStat<REGMonthpré?(
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                {ReguMonthStat}
                                <div style={{ width: '20px', height: '20px' }}><Arrow1 /></div>

                            </div>
                        ) : (

                            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                            {ReguMonthStat}
                            <div style={{ width: '20px', height: '20px' }}><Arrow /></div>

                        </div>


                        )}</span>
                        </div>
                        <div >
                            <span class='normal text-xl font-mediumbold'  style={{color:'#F0F2F5'}}>Mois précédent : {REGMonthpré}</span>
                        </div>
                    </div>
                </div>
                </div>
                <DescriptionHH  text={`Autorisation économiques délivrées en ${currentMonthName} ${year}`} />
                <IndecateurglobalEco />

            </HomeLayout>

        </>
    )
}
export default IndicateursGlobaux;
