import React from "react"
import {Route,Routes} from "react-router-dom"
import IndicateursGlobaux from "./pages/IndicateursGlobaux"
import App from "./App"
import StatPC from "./pages/StatPC"
import StatPH from "./pages/StatPH"
import StatDML from "./pages/StatDML"
import StatRFC from "./pages/StatRFC"
import StatRCP from "./pages/StatRCP"
import StatSD from "./pages/StatSD"
import StatEC from "./pages/StatEC"
import StatAFP from "./pages/StatAFP"
import StatOAN from "./pages/StatOAN"
import StatODPRT from "./pages/StatODPRT"
import StatMapPC from "./pages/StatMapPC"
//import update from "./pages/update"
export default function Myroutes(){

    return (


        <Routes>
        <Route path="/" element={ <App/>}>
        </Route>
        <Route path="/OpenData"
        element={<IndicateursGlobaux/>}>
        </Route>
        <Route path="/OpenData/PermisConstruire"
        element={<StatPC/>}>
        </Route>
        <Route path="/OpenData/PermisHabiter"
        element={<StatPH/>}>
        </Route>
        <Route path="/OpenData/Demolition"
        element={<StatDML/>}>
        </Route>
        <Route path="/OpenData/Reception"
        element={<StatRCP/>}>
        </Route>
        <Route path="/OpenData/Refection"
        element={<StatRFC/>}>
        </Route>
        <Route path="/OpenData/DeclarationANR"
        element={<StatSD/>}>
        </Route>
        <Route path="/OpenData/ANR"
        element={<StatEC/>}>
        </Route>
        <Route path="/OpenData/OAN"
        element={<StatOAN/>}>
        </Route>
        <Route path="/OpenData/AFP"
        element={<StatAFP/>}>
        </Route>
        <Route path="/OpenData/ODPRT"
        element={<StatODPRT/>}>
        </Route>
        <Route path="/GeoData"
        element={<StatMapPC/>}>
        </Route>
        </Routes>
    )
}