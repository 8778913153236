import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';

import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


function LineChart2(props) {
    const [data, setData] = useState([]);

 
    useEffect(() => {
        const getData = async () => {
            if(props.pref){
                var valeur;
                if(props.com){
                     valeur =props.com
                    if(valeur.includes("'")){
                        valeur = valeur.replace(/'/g, "''");
                    }
                    await axios.get(`https://analytics.karaz.org/api/Economique/Evolution de la durée moyenne de délivrance des dossiers d'ODP régie & télécome?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape eco ODPRT"."prefecture"='`+valeur+`'`, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        setData(response.data.data)
                    })

                }else{
                     valeur =props.pref
                    if(valeur.includes("'")){
                        valeur = valeur.replace(/'/g, "''");
                    }
                    await axios.get(`https://analytics.karaz.org/api/Economique/Evolution de la durée moyenne de délivrance des dossiers d'ODP régie & télécome?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape eco ODPRT"."prefecture"='`+valeur+`'`, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        setData(response.data.data)
                    })
                }
               
            }else{
                await axios.get(`https://analytics.karaz.org/api/Economique/Evolution de la durée moyenne de délivrance des dossiers d'ODP régie & télécome?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    setData(response.data.data)
                })
            }
        };
        getData();
    }, [props.pref,props.com]);
var Data ={}
if(data.length === 0){
     Data = {
        labels: ['2020', '2021', '2022', '2023', '2024'],
        datasets: [{
            label: 'Durée moyenne (j)',
            data:[0,0,0,0],
            backgroundColor: [
                'rgba(194, 128, 162, 0.8)',
                'rgba(78, 141, 140, 0.8)',
                'rgba(152, 166, 92, 0.8)',
                'rgba(132, 216, 201, 0.8)',
                'rgba(74, 5, 79, 0.8)',
                'rgba(17, 63, 158, 0.8)'
            ],
            borderWidth: 4,
            borderColor: 'rgba(135,206,250,0.4)',
            tension: 0.2,
            hidden: false,
        }
        ]
    }
}
if(data.length !== 0){
     Data = {
        labels: data?.map(x => x["Year de/du datedelivrance"]),
        datasets: [{
            label: 'Durée moyenne (j)',
            data:data?.map(x => x["Avg duree"]),
            backgroundColor: [
                'rgba(194, 128, 162, 0.8)',
                'rgba(78, 141, 140, 0.8)',
                'rgba(152, 166, 92, 0.8)',
                'rgba(132, 216, 201, 0.8)',
                'rgba(74, 5, 79, 0.8)',
                'rgba(17, 63, 158, 0.8)'
            ],
            borderWidth: 4,
            borderColor: 'rgba(135,206,250,0.4)',
            tension: 0.2,
            hidden: false,
        }
        ]
    }
}
    

    var option = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: true,
          },
        },
        scales: {
          x: {
            grid: {
              display: false
            },
            title: {
                display: true,
                text: 'Date délivrance'
            }
          },
          y: {
            grid: {
              display: true
            },
            title: {
                display: true,
                text: 'Durée moyenne (j)'
            }
          }
        }
      };
  return (

      <div class="bg-gray-100	 row-span-2 col-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{width:'550px' ,position:'relative'}}>
      <div><p class="font-sans md:font-serif font-medium pb-1" style={{paddingLeft:'15px'}}>Evolution  de la durée moyenne  de délivrance des dossiers d'ODP régie & télécome</p></div>
          <Line data={Data} options={option}/>
  
  </div>
  )
}

export default LineChart2