import React  from 'react';
import 'antd/dist/antd.min.css';
import './Style.css';
import {Breadcrumb  } from 'antd';
import pc2 from './svgs_img/pc2.svg';
import ph2 from './svgs_img/ph2.svg';
import rfc2 from './svgs_img/rfc2.svg';
import rcp2 from './svgs_img/rcp2.svg';
import dml2 from './svgs_img/dml2.svg';
import anr2 from './svgs_img/anr2.svg';
import oan2 from './svgs_img/oan2.svg';
import afp2 from './svgs_img/afp2.svg';
import odprt2 from './svgs_img/odprt2.svg';


function Breadcrumbs(props){
const icons = { pc2, ph2,rfc2,rcp2,dml2,anr2,oan2,afp2,odprt2 };
return (
<div style={{marginTop:"10px"}}>
    <br/>
    <br/>
<h1 style={{color:"#374151",fontWeight: 700,fontSize: "22px"}}>Bienvenue sur la rubrique "Données ouvertes" de Rokhas Analytics </h1>
<Breadcrumb>
    <Breadcrumb.Item style={{color:"#6B7280"}}>
      {props.home}
    </Breadcrumb.Item>
    <Breadcrumb.Item >
    <img src={icons[props.icon]} alt="icon" className="icon" width="15px" style={{marginRight:"5px"}}/>      
      <span style={{color:"#0369A1"}}>{props.text}</span>
    </Breadcrumb.Item>
  </Breadcrumb>
  </div>
)
}
export default Breadcrumbs;
