import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, BarElement, LinearScale, CategoryScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'


ChartJS.register(
    LinearScale,
    BarElement,
    CategoryScale
)


function BarChart(props) {


    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    //console.log(startDate.getMonth())
    const [Data, setData] = useState([]);


    useEffect(() => {
        const monthNames = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        const getData = async () => {
            if (props.pref) {

                var valeur = props.pref
                if (valeur.includes("'")) {
                    valeur = valeur.replace(/'/g, "''");
                }
                if (props.com) {
                    var valeur1 = props.com
                    if (valeur1.includes("'")) {
                        valeur1 = valeur1.replace(/'/g, "''");
                    }
                    var comfapi;
                    if(props.annee){
                        comfapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape PC"."ARRONDISSEMENT"='` + valeur1 + `' AND "AVIS_COMMISSION".'ANNEE'='`+props.annee+`'`
                    }else{
                        comfapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape PC"."ARRONDISSEMENT" ='` + valeur1 + `'`;
                    }

                    await axios.get(comfapi, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        //console.log(response.data.data)
                        setData(response.data.data)
                    })

                } else {
                    var prefapi;
                    if(props.annee){
                        prefapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape PC"."PREFECTURE"='` + valeur + `' AND "AVIS_COMMISSION".'ANNEE'='`+props.annee+`'`
                    }else{
                        prefapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="Durée etape PC"."PREFECTURE"='` + valeur + `'`
                    }
                    await axios.get(prefapi, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["DATE_COMMISSION"] !== "").map(item => {
                                const monthNumber = parseInt(item.DATE_COMMISSION.split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; 
                                return { ...item, DATE_COMMISSION: monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["DATE_COMMISSION"] !== ""))
                        }
                    })

                }
            }
            else {
                var apiel;
                if(props.annee){
                    apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="AVIS_COMMISSION"."ANNEE"='`+props.annee+`'`
                }else{
                    apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution des avis membres commissions des dossiers permis de construire?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON`;
                }
                await axios.get(apiel, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    if(props.annee){
                        setData(response.data.data.filter(item => item["DATE_COMMISSION"] !== "").map(item => {
                            const monthNumber = parseInt(item.DATE_COMMISSION.split('-')[0], 10);
                            const monthName = monthNames[monthNumber - 1]; 
                            return { ...item, DATE_COMMISSION: monthName };
                          }))
                    }else{
                        setData(response.data.data.filter(item => item["DATE_COMMISSION"] !== ""))
                    }
                 

                })
            }

        };
        getData();
    }, [props.pref, props.com,props.annee]);
    //console.log(Data.length)
    var data = {}
    if (Data.length === 0) {
        data = {
            labels: ['2018', '2018', '2019', '2020', '2021', '2022', '2020'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(16, 185, 129, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: false,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: false,

            }
            ]
        }
    }
    //console.log(Data)

    function getUnique(array) {
        var uniqueArray = [];
        var i = 0
        // Loop through array values
        for (i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    if (Data.length !== 0) {
        var n;
        var l;
        if(props.annee){
             n = Data?.filter(x => x['DECISION_S'] === 'Défavorable').map(x => x["DOSSIER_ID Count"])
           l = Data?.filter(x => x['DECISION_S'] === "Favorable").map(x => x["DOSSIER_ID Count"])
            
        }else{
          n = Data?.filter(x => x['DECISION'] === 'Défavorable').map(x => x["DOSSIER_ID Count"])
         l = Data?.filter(x => x['DECISION'] === "Favorable").map(x => x["DOSSIER_ID Count"])
        }
        
        if (l.length !== n.length) {
            if (l.length > n.length) {
                n.unshift(0)
            } if (l.length < n.length) {
                l.unshift(0)
            }
        }
        //console.log("hello i'm N"+n[0])
        data = {
            labels: getUnique(Data?.map(x => x['DATE_COMMISSION'])),
            datasets: [{
                label: "Nombre d'avis",
                data: n,
                backgroundColor: [
                    'rgba(16, 185, 129, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: "Nombre d'avis",
                data: l,
                backgroundColor: [
                    'green'
                ],
                borderWidth: 2,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }

    }


    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        },
        title: {
            display: true,


        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'DATE COMMISSION'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: "Nombre d'avis"
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
    return (
        <div class="bg-gray-100	 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 respodiv " style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-3" style={{width:'100%'}}>
                <p class="font-sans md:font-serif font-medium pb-1" style={{paddingLeft:'15px'}}>Evolution de nombre de dossier permis de construire e-déposé par catégorie {props.annee?' en '+props.annee:''}</p>
                <div class="mb-2">
                </div>
            </div>
            <div class="flex flex-row mt-3" >
                <div class="ml-3" style={{ display: "block", height: "260px",width:'73%' }}>
                    <Bar data={data} options={options} />
                </div>

                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "23%" }}>
                    <div >

                        <input
                            type="checkbox"
                            name="dataset1"
                            checked={checkboxes.dataset1}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc3'
                        />
                        <label class="">Défavorable</label>


                    </div>
                    <div>


                        <input
                            type="checkbox"
                            name="dataset2"
                            checked={checkboxes.dataset2}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc4'
                        />
                        <label class="mr-3"> Favorable</label>


                    </div>


                </div>
            </div>


        </div>

    )
}

export default BarChart