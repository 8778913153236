import React ,{ useState,useEffect} from 'react';
import 'antd/dist/antd.min.css';
import './Style.css';
import analyticsLogo from './svgs_img/analyticsLogo.svg';
import urbaLogo from './svgs_img/urbaLogo.svg';
import LogoEco from './svgs_img/LogoEco.png';
import geoData from './svgs_img/mapSVGIcon.svg'
import pc from './svgs_img/pc.svg';
import ph from './svgs_img/ph.svg';
import rfc from './svgs_img/rfc.svg';
import dml from './svgs_img/dml.svg';
import rcp from './svgs_img/rcp.svg';
import anr from './svgs_img/anr.svg';
import ec from './svgs_img/ec.svg';
import afp from './svgs_img/afp.svg';
import odp from './svgs_img/odp.svg';
import odprt from './svgs_img/odprt.svg';
import edges from './svgs_img/edges.svg';
import smile from './svgs_img/smile.svg';
import LogoFooter from './svgs_img/LogoFooter.svg';
//import RokhasIndex from './svgs_img/RokhasIndex.png'
import { Layout, Menu,Divider ,Drawer,Col,Row } from 'antd';
import { Link, useNavigate  } from "react-router-dom";

const { Header, Content, Footer } = Layout;

function HomeLayout({children}){
  let navigate = useNavigate(); 

  const [isShowDrawer, setshowDrawer] = useState(false)
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1030);

  const updateMedia = () => {
     setDesktop(window.innerWidth > 1030);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
const showDrawer = () => {
  setshowDrawer(true);
};
const OnClose = () => {
  setshowDrawer(false);
};
  function getItem(label, key, icon, onClick,className,children) {
    return {
      key,
      icon,
      onClick,
      className,
      children,
      label,
    };
  }
  const items = [
    getItem('Géoportail','subb0', <img src={geoData} alt="logoindex" width={35}/>,() => navigate('/GeoData'),'Geoportal',null),
    getItem('Urbanisme', 'sub1', <img src={urbaLogo} alt="logourba" />,null,"MyMenu", [
    getItem("Permis de construire", '1',<img src={pc} alt="logopc" width={30}/>,() => navigate('/OpenData/PermisConstruire'),"sousMenue"), 
    getItem('Réfection', '2',<img src={rfc} alt="logorfc" width={30}/>,() => navigate('/OpenData/Refection')), 
    getItem('Démolition', '3',<img src={dml} alt="logodml" width={30}/>,() => navigate('/OpenData/Demolition')), 
    getItem("Permis d’habiter" , '4',<img src={ph} alt="logoph" width={30} />,() => navigate('/OpenData/PermisHabiter')), 
    getItem('Réception', '5',<img src={rcp} alt="logorcp" width={30}/>,() => navigate('/OpenData/Reception'))],"myMenu"),
    getItem('Economique', 'sub2', <img src={LogoEco} alt="logoeco" width={33} height={40} />,null,"MyMenu", [
    getItem("Déclaration ANR", '6',<img src={anr} alt="logosd" width={30}/>,() => navigate('/OpenData/DeclarationANR')),
    getItem('ODP courant', '7',<img src={odp} alt="logoodp" width={30}/>,() => navigate('/OpenData/OAN')),
    getItem("ODP Régie & Télécom", '8',<img src={odprt} alt="logoodprt"width={30} />,() => navigate('/OpenData/ODPRT')),
    getItem("Affichage publicitaire", '9',<img src={afp} alt="logoafp" width={30}/>,() => navigate('/OpenData/AFP')),
    getItem("Activité non réglementé", '10',<img src={ec} alt="logoEC" width={30}/>,() => navigate('/OpenData/ANR')),
    ]),
    // getItem(<a href="https://rokhas.ma/karazal/#search//karaz/ux/hub/portailsearch/search/ReportRanking//search"  target="_blank" rel="noreferrer">Rokhas Index</a>, "menu3", <img src={RokhasIndex} alt="logoindex" width={50}/>),
  ];
  
return(
    <Layout>
    <Header style={{backgroundColor:"white",height:"75px",zIndex: "100", borderBottom: "1px solid #D1D5DB"}}>
     <nav style={{marginTop:"10px",marginLeft:"10px"}}>
     <div className="logo" onClick={() => navigate('/')}>
     <img src={analyticsLogo} alt="logo" style={{float:'left', display: 'inline-block'}} onClick={() => navigate('/')}/>
        </div>
        <div className="menuCon">
          <div className="rightMenu">
          <Menu mode="horizontal" items={items} style={{marginLeft: "50%"}} className="mainMenu"/>
          </div>
      <div className="barsMenu barsBtn" onClick={showDrawer}>
     <img src={analyticsLogo} alt="logo" style={{float:'left'}}/>
        </div>
          <Drawer
         title = <img src={analyticsLogo} alt="logo" style={{float:'left', display: 'inline-block'}}/>
            placement="left"
            closable={false}
            onClose={OnClose}
            open={isShowDrawer}
          >
                    <Menu  items={items} className="DrawerMenu" mode='inline'/>

          </Drawer>
          </div>
     </nav>
</Header>
    <Content className='sahbtest' style={{overflow: "hidden"}}>{children}</Content>
   { isDesktop ? (
      <Footer style={{    padding: "0",
      width: "-webkit-fill-available",
      height: "538px",
      position: "relative",
      overflow: "hidden"}}>
  <div style={{
    borderTop: "10px solid #0284C7",
    background: "#0369A1",
    height: "518px",
    position: "relative",
    width: "100%",
    transform: "rotate(-6deg) translateY(100px) scale(1.1)"}}>
       <img src={smile} alt="smile" className="smile"/>
    <img src={edges} alt="edgeRight" className="edgeRight"/>
    <img src={edges} alt="edgeLeft" className="edgeLeft"/>
         <div className='Wrapper'>
    <div className='topFooter' style={{zIndex:2,position:"absolute"}}>
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={8}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>URBANISME</div>
      </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' ,zIndex:2}} to="/OpenData/PermisConstruire">Permis de construire</Link>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/PermisHabiter">Permis d'habiter</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/Reception">Réception</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/Demolition">Démolition</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/Refection">Réfection</Link>
       </Row>
      </Col>
      <Col className="gutter-row" span={8}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>ECONOMIQUE</div>
      </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/DeclarationANR">Déclaration préalable ANR</Link>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/ANR">Activité non réglementée</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/OAN">ODP courant</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/AFP">Affichage publicitaire</Link>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Link style={{padding: '8px 0' }} to="/OpenData/ODPRT">ODP régie & Télécom </Link>
       </Row>
      </Col>
      <Col className="gutter-row" span={8}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>LIENS UTILES</div>
      </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}><a href='https://rokhas.ma/karazal/'  target='_blank' rel="noreferrer">Rokhas.ma</a></div>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}> <a href="https://rokhas.ma/karazal/#search//karaz/ux/hub/portailsearch/search/ReportRanking//search" target="_blank" rel="noreferrer">Rokhas Index</a></div>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>Guides pratiques</div>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>Conditions générales</div>
       </Row>
       <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <div style={{padding: '8px 0' }}>Référentiel juridique</div>
       </Row>
      </Col>
    
    </Row>
    </div>
      <div className='bottomFooter' style={{position:"absolute"}}>
      <Divider style={{background: "white"}} />
      <div className='RightFooter' style={{float:"right",position:'relative'}}>
      <div className='SocialIcons' >
      <a href="https://www.facebook.com/Rokhas.ma" target='_blank' rel="noreferrer"><i className="fa-brands fa-facebook-f social"></i></a>
      <a href="https://www.youtube.com/c/Rokhas" target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube social"></i></a>
     <a href="https://www.linkedin.com/company/ribatis/mycompany/?viewAsMember=true" target='_blank' rel="noreferrer"> <i className="fa-brands fa-linkedin social"></i></a>
      </div>
        <p style={{padding:"10px",color:"white",fontSize:"10px",marginRight: "107px",marginTop: "11px"}}>© 2023 ROKHAS. POWERED BY KARAZ & OPERATED BY RIBATIS - TOUS DROITS RÉSERVÉS.</p>
 </div>
 <img src={LogoFooter} alt="logoFooter" style={{padding: "10px",float:'left', display: 'inline-block'}}/>       
      <p style={{width:"50%",color:"white",fontSize:"13px"}}>Un espace décisionnel Self-Service permettant aux analystes et décideurs d’accéder à un panel de tableaux de bords riches et interactifs.</p>
 
 </div>
          </div>
    </div>
   
 </Footer>) : (<Footer style={{padding:0,backgroundColor:"rgb(3, 105, 161)"}}>
      <div style={{backgroundColor:"rgb(3, 105, 161)"}}>
      <div className='bottomFooter'>
      <div className='RightFooter' style={{float:"right",position:'relative'}}>
      <div className='SocialIcons' >
      <i className="fa-brands fa-facebook-f social"></i>
      <i className="fa-brands fa-instagram social"></i>
      <i className="fa-brands fa-twitter social"></i>
      </div>
        <p style={{padding:"10px",color:"white",fontSize:"10px",marginRight: "4px",marginTop: "25px"}}>© 2023 ROKHAS. POWERED BY KARAZ & OPERATED BY RIBATIS - TOUS DROITS RÉSERVÉS.</p>
 </div>
 <div className='LeftFooterS'>
 <img src={LogoFooter} alt="logoFooter" style={{padding: "10px",float:'left', display: 'inline-block'}}/>       
      </div>
 </div>
      </div>
    </Footer>)}
  </Layout>
)
}
export default HomeLayout;
