import React, {useState,useEffect} from 'react'
import {Chart as ChartJS, BarElement, LinearScale, CategoryScale} from 'chart.js'
import {Bar} from 'react-chartjs-2'

import axios from 'axios'


ChartJS.register(
    LinearScale,
    BarElement,
    CategoryScale
)


function BarChart(props) {
  const [Data, setData] = useState([]);

  const [checkboxes, setCheckboxes] = useState({
    dataset1: true,
    dataset2: true
});
const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    console.log(checked)
    setCheckboxes({ ...checkboxes, [name]: checked });
};


    
    useEffect(() => {
        const getData = async () => {
            if(props.prefcommun){
                var valeur =props.prefcommun
                if(valeur.includes("'") || valeur.includes("’") ){
                    valeur = valeur.replace(/'/g, "''");
                    valeur = valeur.replace(/’/g, "'");
                    valeur = valeur.replace(/'/g, "''");
                }
                await axios.get(`https://analytics.karaz.org/api/Economique/Evolution des avis des membres de commission- odprt?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&PREFECTURE=PRÉFECTURE AIN CHOCK&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_AUTORISATIONODPRT"."prefecture"='`+valeur+`'`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                    setData(response.data.data)
                })
    
            }else{
    
                await axios.get(`https://analytics.karaz.org/api/Economique/Evolution des avis des membres de commission- odprt?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                 console.log(response.data.data)
                    setData(response.data.data)
                })
            }
        };
        getData();
    }, [props.prefcommun]);
//console.log(Data.length)
var data ={}
if(Data.length === 0){
   data = {
    labels: ['2020', '2021', '2022', '2023', '2024'],
    datasets: [{
        label: 'test1',
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
            'rgba(194, 128, 162, 0.8)'
        ],
        borderWidth: 2,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
    },
    {
        label: 'test2',
        data: [0, 0, 0, 0, 0],
        backgroundColor: [
            'green'
        ],
        borderWidth: 2,
        borderColor: 'rgba(120,150,250,0.4)',
        tension: 0.2,
        hidden: false,

    }
    ]
}

}
//console.log(Data)
function getUnique(array) {
    var uniqueArray = [];
    var i = 0
    // Loop through array values
    for (i = 0; i < array.length; i++) {
        if (uniqueArray.indexOf(array[i]) === -1) {
            uniqueArray.push(array[i]);
        }
    }
    return uniqueArray;
}
if(Data.length !== 0){
    var n=Data?.filter(x=>x['DECISION_ESIGN'] === 'Favorable').map(x=>x["DOSSIER_ID Count"])
    var l=Data?.filter(x=>x['DECISION_ESIGN'] === 'Défavorable').map(x=>x["DOSSIER_ID Count"])
    if(l.length !== n.length){
        if(l.length > n.length ){
            n.unshift(0)
        }if(l.length < n.length ){
            l.unshift(0)
        }
    }
   data = {
    labels:getUnique(Data?.map(x => x['Year de/du DATE_REUNION'])),
    datasets: [{
        label: 'Favorable',
        data:n,
        backgroundColor: [
            'rgb(190 242 100)'
        ],
        borderWidth: 2,
        borderColor: 'green',
        tension: 0.2,
        hidden: !checkboxes.dataset1,
    },
    {
        label: 'Défavorable',
        data: l,
        backgroundColor: [
            'green'
        ],
        borderWidth: 2,
        borderColor: 'rgba(120,150,250,0.4)',
        tension: 0.2,
        hidden: !checkboxes.dataset2,

    }
    ]
}

}
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            }
        },
        title: {
            display: true,
            

        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date commision'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Nombre avis'
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }

        }
    }
  return (
    <div class="bg-gray-100 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500 respodiv" style={{ display: "block",height:'100%' }}>
    <div className="ml-2 items-center pt-3">
    <p class="font-sans md:font-serif font-medium pb-1" style={{width:'100%'}}>Evolution des avis des membres de commission des dossiers ODP courant</p>
    <div class="mb-2">
    </div>
    </div>

    <div class="flex flex-row " >
        <div class="ml-3" style={{ display: "block", height: "260px",width:'72%' }}>

            <Bar data={data} options={options} />

        </div>
        <div class="flex flex-col justify-center items-center place-content-center pt-20"  style={{ display: "block", width: "22%" }}>
           
        <div>
                        <div >

                            <input
                                type="checkbox"
                                name="dataset1"
                                checked={checkboxes.dataset1}
                                onChange={handleCheckboxChange}
                                class="mr-1 ml-3 h-4 w-4 rounded-full shadow"
                            />
                            <label class=""> Favorable </label>


                        </div>
                        <div>


                            <input
                                type="checkbox"
                                name="dataset2"
                                checked={checkboxes.dataset2}
                                onChange={handleCheckboxChange}
                                class="bg-sky-200 mr-1 ml-3 h-4 w-4 rounded-full shadow"
                            />
                            <label class="mr-3"> Défavorable  </label>


                        </div>


                    </div>



        </div>
    </div>


</div>

  )
}

export default BarChart