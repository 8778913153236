import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend);


function Demidoughnut1(props) {
  const [Data, setData] = useState([]);


  useEffect(() => {
    const getData = async () => {
      if (props.pref) {
        var valeur;
        if(props.com){
           valeur = props.com
          if(valeur.includes("'")){
              valeur = valeur.replace(/'/g, "''");
          }
          var apicom;
          if(props.annee){
            apicom=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_AUTORISATIONPH"."COMMUNE" ='`+valeur+`'`+props.annee+`'`
          }else{
            apicom=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_AUTORISATIONPH"."COMMUNE" ='`+valeur+`'`
          }
          await axios.get(apicom, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
       
            setData(response.data.data)
          
          })

        }else{
           valeur = props.pref
          if(valeur.includes("'")){
              valeur = valeur.replace(/'/g, "''");
          }
          if (valeur ==='PREFECTURE DE RABAT'){
            valeur ='PRÉFECTURE DE RABAT'
        }
        var apipre;
        if(props.annee){
          apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_AUTORISATIONPH"."prefecture" ='`+valeur+`' AND "ANNEE"='`+props.annee+`'`
        }else{
          apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_AUTORISATIONPH"."prefecture" ='`+valeur+`'`
        }
          await axios.get(apipre, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            
            setData(response.data.data)
           
          })
        }
      } else {
        var preapi;
        if(props.annee){
          preapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="ANNEE"='`+props.annee+`'`
        }else{
          preapi=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/délai moyen par jourPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`
        }
        await axios.get(preapi, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          
          setData(response.data.data)
         
        })
      }
    };
    getData();
  }, [props.pref,props.com,props.annee]);
 
  var data ={}
  if (Data.length === 0) {
     data = {
      labels: ['Grands Projet', 'Range'],
      datasets: [{
        data: [
          { value: 0, label: "" },
          { value: 0, label: "" }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }

  if (Data.length !== 0) {
    var y;
    if (Data.length % 2 === 0) {
      
       y = Data.find(item => item.CATEGORIE === "Grands projets")['Avg duree_delivrance']
    } else {
  
       y = '0'
    }


     data = {
      labels: ["Grands projets", "Range"],
      datasets: [{
        data: [
          { value: parseFloat(y) },
          { value: 18 }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }
  var options = {
    cutout: 35,
    // rotation: props.rotation,
    //circumference: props.circumference,
    datalabels: {
      color: '#fff',
    },
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    rotation: -90,
    circumference: 180,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'The title',
            font: {
              size: '60'
            }
          }]

      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        //beginAtZero: true,
        display: false,
        barPercentage: 0.4,
      }
    },

  }

  return (
    <div class="bg-gray-100	  rounded-lg  drop-shadow-md shadow-md shadow-blue-100/500 pt-2">
    <div>
    <div class="pl-8 pr-4 pt-2">
    <p class="font-sans md:font-serif font-medium"> Délai moyen délivrance Grands projets  </p>
    </div>
     
      <div>
        <Doughnut data={data} options={options}
          style={{ height: "80px"}}
        />
      </div>
    </div>
    <p class="font-sans md:font-serif font-medium pl-20 pb-2"> Délai moyen/J: {y}</p>

  </div>

  )
}

export default Demidoughnut1