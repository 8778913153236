import React, { useState, useEffect } from 'react'

import axios from 'axios'

function MonthsPrec(props) {
    const [Data, setData] = useState(0);


    useEffect(() => {
        const getData = async () => {
            if (props.pref) {
                var valeur = props.pref
                if (valeur.includes("'")) {
                    valeur = valeur.replace(/'/g, "''");
                }
                await axios.get(`https://analytics.karaz.org/api/Economique/mois precedent ODPRT?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="prefecture" ='` + valeur + `'`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    console.log(response.data.data)
                    var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                    var somme = 0
                    for (let i = 0; i < l.length; i++) {
                        somme += l[i];
                    }
                    setData(somme)
                })

            } else {
                await axios.get(`https://analytics.karaz.org/api/Economique/mois precedent AFP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                    "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    console.log(response.data.data)
                    var l = response.data.data.map(x => parseInt(x['dossier_id Nombre distinct']))
                    var somme = 0
                    for (let i = 0; i < l.length; i++) {
                        somme += l[i];
                    }
                    setData(somme)
                })

            }

        };
        getData();
    }, [props.pref]);

    return (
        <div  class="bg-gray-100 row-span-1 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{height:'130px'}}>
        <div class="pl-5 pr-5" style={{height:'35px'}}> <p class="font-sans md:font-serif font-medium pb-1">Nombre de dossiers e-déposé le précédent</p></div>
    <div class=" pt-4" style={{paddingLeft:'120px'}}><p class="font-bold text-4xl ">{Data}</p>
    </div>
</div>
    )
}

export default MonthsPrec