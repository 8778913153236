import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend);


function Demidoughnut1() {
  const [Data, setData] = useState([]);

  
  useEffect(() => {
    const getData = async () => {
      await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/délai moyen par jourRCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
       console.log(response.data.data)
          setData(response.data.data)
      })
  };
      getData();
  }, []);
var data={}
if(Data.length === 0){
   data = {
    labels: ['Grands Projet', 'Range'],
    datasets: [{
      data: [
        { value: 0, label: "" },
        { value: 0, label: "" }
      ],
      backgroundColor: [
        'rgba(120,150,250,0.4)', 'transparent'
      ],
      borderWidth: 5,
      hoverOffset: 4,
      borderColor: 'rgba(135,206,250,0.4)',
      tension: 0.2,
      hidden: false,
    }
    ]
  }
}

if(Data.length !== 0){
  var x;
  var y;
   x =Data[0]['Avg duree']
   y =Data[2]['Avg duree']
  
   data = {
    labels: ['Grands Projet', 'Petits Projet'],
    datasets: [{
      data: [
        { value: parseFloat(x)},
        { value: parseFloat(y)}
      ],
      backgroundColor: [
        'rgba(120,150,250,0.4)', 'transparent'
      ],
      borderWidth: 5,
      hoverOffset: 4,
      borderColor: 'rgba(135,206,250,0.4)',
      tension: 0.2,
      hidden: false,
    }
    ]
  }
}
  var options = {
    cutout: 35,
    // rotation: props.rotation,
    //circumference: props.circumference,
    datalabels: {
      color: '#fff',
    },
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    rotation: -90,
    circumference: 180,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'The title',
            font: {
              size: '60'
            }
          }]

      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        //beginAtZero: true,
        display: false,
        barPercentage: 0.4,
      }
    },

  }
  return (
    <div class="bg-gray-100	  rounded-lg  drop-shadow-md shadow-md shadow-blue-100/500" style={{display:'flex',alignItems:'center',width:"300px",justifyContent:'center',flexDirection:'column'}}>
      <div>
        <span class="font-sans md:font-serif font-medium "> Délai moyen/J pour les  dossiers d’autorisation de réception</span>
        </div>
        <div>
          <Doughnut data={data} options={options}
            style={{ height: "80px"}}
          />
        </div>
        <div>
        <span class="font-sans md:font-serif font-medium"> Délai moyen/J: {y}</span>
        </div>

    </div>

  )
}

export default Demidoughnut1