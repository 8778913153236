import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import axios from 'axios'

ChartJS.register(ArcElement, Tooltip, Legend);


function Demidoughnut1(props) {
  const [Data, setData] = useState([]);

  useEffect(() => {
    
  const getData = async () => {
    if (props.pref) {
      //console.log("test test test"+props.pref)
      var valeur =props.pref
      if(valeur.includes("'")){
          valeur = valeur.replace(/'/g, "''");
      }
      if(props.com){
        var valeur1 =props.com
        if(valeur1.includes("'")){
            valeur1 = valeur1.replace(/'/g, "''");
        }
        await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/délai moyen par jour DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="DIM_LIEU"."ARRONDISSEMENT" ='`+valeur1+`'`, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          console.log(response.data.data)
          setData(response.data.data)
          console.log(response.data.data)
        })

      }else{
        await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/délai moyen par jour DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="DIM_LIEU"."prefecture" ='`+valeur+`'`, {
          "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
          console.log(response.data.data)
          setData(response.data.data)
          console.log(response.data.data)
        })

      }
     
    } else
     {
      await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/délai moyen par jour DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
        "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
        console.log(response.data.data)
        setData(response.data.data)
        console.log(response.data.data)
      })
    }
  };
    getData();
  }, [props.pref,props.com]);
  // console.log(Data)
  var data ={}
  if (Data.length === 0) {
     data = {
      labels: ['Grands Projet', 'Range'],
      datasets: [{
        data: [
          { value: 0, label: "" },
          { value: 0, label: "" }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }

  if (Data.length !== 0) {
    //console.log("wach makhdamch")

      var x = Data?.map(x=>parseFloat(x['Avg duree']))
      var somme = 0
      for (let i = 0; i < x.length; i++) {
        somme += x[i];
      }

    
var avg =parseFloat(somme/x.length).toFixed(2)
     data = {
      labels: ["Permis de Construire", "Range"],
      datasets: [{
        data: [
          { value: avg },
          { value: 18 }
        ],
        backgroundColor: [
          'rgba(120,150,250,0.4)', 'transparent'
        ],
        borderWidth: 5,
        hoverOffset: 4,
        borderColor: 'rgba(135,206,250,0.4)',
        tension: 0.2,
        hidden: false,
      }
      ]
    }
  }
  var options = {
    cutout: 35,
    // rotation: props.rotation,
    //circumference: props.circumference,
    datalabels: {
      color: '#fff',
    },
    maintainAspectRatio: false,
    cutoutPercentage: 50,
    rotation: -90,
    circumference: 180,
    plugins: {
      doughnutlabel: {
        labels: [
          {
            text: 'The title',
            font: {
              size: '60'
            }
          }]

      },
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        //beginAtZero: true,
        display: false,
        barPercentage: 0.4,
      }
    },

  }

  return (
    <div class="bg-gray-100	  rounded-lg  drop-shadow-md shadow-md shadow-blue-100/500" style={{width:"300px"}}>
       <div class="pl-14 pr-5" style={{height:'50px',width:'300px'}}>
      <p class="font-sans md:font-serif font-medium pb-1">Délai moyen/J pour dossiers d'autorisation de démolitiont</p>
      </div>
      <div>
        <div>
          <Doughnut data={data} options={options}
            style={{ height: "80px"}}
          />
        </div>
      </div>
      <p class="font-sans md:font-serif font-medium pb-2 pt-2 pl-20"> Délai moyen/J: {avg}</p>

    </div>

  )
}

export default Demidoughnut1