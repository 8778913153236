import React  from 'react';
import 'antd/dist/antd.min.css';
import './Style.css';
import { ReactComponent as UrbaLo } from '../components/svgs_img/urbaLogo.svg';
function DescriptionH(props){
    console.log("this is props "+props);
return (
<div className='DescWrapper'>
<h1 className="descTitle" style={{float:"left",color:"#374151",marginLeft: "25px"}}>{props.title}</h1>
<br/><br/>
<div className='descBody'>
<UrbaLo/>
<p style={{paddingLeft:'20PX',float:"right",color:"#374151",textAlign: "left",fontSize:'26px',fontWeight:'500',lineHeight:'50px'}}>{props.text}</p>
</div>
    </div>
)
}
export default DescriptionH;