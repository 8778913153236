import React, { useState, useEffect } from 'react'
import axios from 'axios'
import 'antd/dist/antd.min.css';
import '../components/Style.css';
import HomeLayout from "../components/HomeLayout";
import Breadcrumbs from '../components/BreadCrumbs';
import LineChart from '../components/charts/ChartEC/LineChart';
import Select from 'react-select';
import BarChart from '../components/charts/ChartEC/BarChart';
import LineChart2 from '../components/charts/ChartEC/LineChart2';
import { ReactComponent as ReactLogo } from '../components/svgs_img/arrow.svg';
import Demidoughnut1 from '../components/charts/ChartEC/Demidoughnut1';
import MonthsStat from '../components/charts/ChartEC/MonthsStat';
import MonthsPrec from '../components/charts/ChartEC/MonthsPrec';
import PieChartWithNeedle from '../components/charts/ChartEC/PieChartWithNeedle';
const StatEC = () => {




    const [prefOptions, setprefOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [prefecture, setprefecture] = useState('');
    const [comOptions, setcomOptions] = useState([]);
    const [commune, setcommune] = useState('');
    console.log(selectedOption)
    console.log(commune)
    const getData = async () => {

        await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/PrefecturesList?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
            "Content-Type": "application/json; charset=utf-8"
        }).then((response) => {
            console.log(response.data.data)
            var mydata = response.data.data
            console.log(mydata)
            var table = []
            var obj = {}
            for (let i = 0; i < mydata.length; i++) {
                obj.label = mydata[i]['prefecture']
                obj.value = mydata[i]['prefecture']
                table.push(obj);
                obj = {}
            }
            setprefOptions(table)
            console.log("this is length ==> " + table.length)
        })
    };
    useEffect(() => {
        const getDatacom = async () => {
            var valeur = prefecture
            if (valeur.includes("'")) {
                valeur = valeur.replace(/'/g, "''");
            }
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/DIM_LIEU?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&PREFECTURE=PRÉFECTURE AIN CHOCK&ZOHO_ERROR_FORMAT=JSON&ZOHO_SELECT_COLUMNS=ARRONDISSEMENT&ZOHO_CRITERIA="prefecture" ='` + valeur + `'`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                var mydata = response.data.data
                console.log(mydata)
                var table = []
                var obj = {}
                for (let i = 0; i < mydata.length; i++) {
                    obj.label = mydata[i]['ARRONDISSEMENT']
                    obj.value = mydata[i]['ARRONDISSEMENT']
                    table.push(obj);
                    obj = {}
                }
                if (prefecture) {
                    setcomOptions(table)
                } else {
                    setcomOptions([])
                }

                console.log("this is length ==> " + table.length)
            })


        };
        getData();
        getDatacom();
    }, [prefecture]);



    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: 40,
            backgroundColor: 'rgb(241 245 249)',
        }),
        menu: (provided) => ({
            ...provided,
            maxHeight: 300,
        }),
    };



    const handleSelectChange = (selectedOption) => {

        if (selectedOption === null) {
            setSelectedOption(null);
            setprefecture('');
        } else {
            setSelectedOption(selectedOption);

            setprefecture(selectedOption['value']);
        }


    };
    const handleSelectChangecom = (selectedOption) => {
        console.log(selectedOption == null)
        if (selectedOption === null) {
            setSelectedOption(null);
            setcommune('');
        } else {
            setSelectedOption(selectedOption);
            console.log(selectedOption)
            setcommune(selectedOption['value']);
        }


    };


    return (
        <>
            <HomeLayout>
                <div style={{ margin: "0 5% 0 5%" }} class="pb-5">
                    <Breadcrumbs home="Economique" icon="anr2" text="Dossiers d'autorisation non réglementé e-déposés" />
                    <div class="selectprefcom" style={{display:'flex' ,width: "100%",flexWrap:'wrap',gap:'20px',}}>
                        <div >
                            <label>Préfecture et Province:</label>
                            <Select placeholder="recherche" options={prefOptions} onChange={handleSelectChange} styles={customStyles} isClearable={true} isMulti={false} />
                        </div>
                        <div >
                            <label>Commune et Arrondissement:</label>
                            <Select placeholder="recherche" options={comOptions} onChange={handleSelectChangecom} styles={customStyles} isClearable={true} isMulti={false} />
                        </div>

                    </div>
                    <div style={{ height: "100%",paddingtop:'10px' }} class="flex flex-row mb-2">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2">Evolution Dossiers  d'autorisation non réglementé e-déposés</label>
                    </div>

                    <div class="rfcparti1">
                        <LineChart pref={prefecture} com={commune} />
                        <div class="monthscontainer">
                            <MonthsStat pref={prefecture} com={commune} />
                            <MonthsPrec pref={prefecture} com={commune} />
                        </div>
                    </div>

                    <div style={{ height: "35px" }} class="flex flex-row mt-4">
                        <ReactLogo />
                        <p class="font-sans md:font-serif font-semibold text-lg ml-2">Durée et Délai du traitement des dossiers d'autorisation de réception</p>
                    </div>

                    <div class="rfcparti1">
                        <LineChart2 pref={prefecture} com={commune} />
                        <div class="monthscontainer">
                            <Demidoughnut1 pref={prefecture} com={commune} />
                         
                        </div>
                    </div>




                    <div style={{ height: "40px" }} class="flex flex-row ">
                        <ReactLogo />
                        <label class="font-sans md:font-serif font-semibold text-lg ml-2"> Avis commision</label>
                    </div>

                    <div class="rfcparti1">

                        <BarChart pref={prefecture} com={commune} />

                        <div class="bg-gray-100	 col-span-1 row-span-2 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500" style={{ display: "block", width: "300px", height: "320px" }} >

                            <div class="pl-10">

                                <PieChartWithNeedle pref={prefecture} />

                            </div>

                        </div>
                    </div>
                </div>
            </HomeLayout>

        </>
    )
}
export default StatEC;