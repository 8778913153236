import React  from 'react';
import 'antd/dist/antd.min.css';
import './Style.css';
import edges from './svgs_img/edges.svg';
import smile from './svgs_img/smile.svg';
function MyHead(props){
console.log("check "+props.title);
return (
<div className="headContainer" style={{padding: "0",
      width: "-webkit-fill-available",
      height: "226px",
      position: "relative",
  
    }}>
  <div style={{
    borderTop: "10px solid #0284C7",
    background: "#0369A1",
    height: "264px",
    position: "absolute",
    width: "-webkit-fill-available",
    orverflow:"hidden",
    transform: "rotate(177deg) translateY(100px) scale(1.1)",zIndex:1}}>
      </div>
      <img src={smile} alt="smile" className="smileMyH" style={{zIndex:2}}/>
    <img src={edges} alt="edgeRight" className="edgeRightMyH" style={{zIndex:2}}/>
    <img src={edges} alt="edgeLeft" className="edgeLeftMyH" style={{zIndex:2}}/>
    <div className='Myheadtext'>
      <h3 style={{zIndex:10,color:"white",textAlign: "center"}}>{props.title}</h3>
      <h3 style={{zIndex:10,color:"white",textAlign: "center"}}>{props.text}</h3>
    </div>
    </div>
)
}
export default MyHead;