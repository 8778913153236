import React ,{ useState,useEffect} from 'react';
import 'antd/dist/antd.min.css';
import './all.min.css';
import './index.css'
import {Button,Col,Row,Card } from 'antd';
import edges from './components/svgs_img/edges.svg';
import landingPageImg from './components/svgs_img/landingPageImg.svg';
import cloud from './components/svgs_img/cloud.svg';
import shareData from './components/svgs_img/shareData.svg';
import geoData from './components/svgs_img/geoData.svg';
import pc2 from './components/svgs_img/pc2.svg';
import ph2 from './components/svgs_img/ph2.svg';
import rfc2 from './components/svgs_img/rfc2.svg';
import rcp2 from './components/svgs_img/rcp2.svg';
import dml2 from './components/svgs_img/dml2.svg';
import anr2 from './components/svgs_img/anr2.svg';
import oan2 from './components/svgs_img/oan2.svg';
import afp2 from './components/svgs_img/afp2.svg';
import odprt2 from './components/svgs_img/odprt2.svg';

import classement from './components/svgs_img/classement.svg';
import smile from './components/svgs_img/smile.svg';
import HomeLayout from "./components/HomeLayout";
import "./components/Style.css";
import MyCards from "./components/Card";
import { useNavigate  } from "react-router-dom";

function App() {
  const [isHovered, setHover] = useState(false);
  const [isHovered2, setHover2] = useState(false);
  const [isHovered3, setHover3] = useState(false);
  const [isHovered4, setHover4] = useState(false);

  const toggleHover=()=>{
    setHover(!isHovered)
  }
  const toggleHover2=()=>{
    setHover2(!isHovered2)

  }
  const toggleHover3=()=>{
    setHover3(!isHovered3);
  }
  const toggleHover4=()=>{
  setHover4(!isHovered4)

  }
  let navigate = useNavigate(); 
    const [isDesktop, setDesktop] = useState(window.innerWidth > 785);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 785);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <HomeLayout>
      {isDesktop ? (
        <div
          className="headContainer"
          style={{
            padding: "0",
            width: "-webkit-fill-available",
            height: "538px",
            position: "relative",
          }}
        >
          <div
            style={{
              borderTop: "10px solid #0284C7",
              background: "#0369A1",
              height: "518px",
              position: "absolute",
              width: "-webkit-fill-available",
              orverflow: "hidden",
              transform: "rotate(175deg) translateY(100px) scale(1.1)",
              zIndex: 1,
            }}
          ></div>
          <img
            src={smile}
            alt="smile"
            className="smile"
            style={{ zIndex: 2 }}
          />
          <img
            src={edges}
            alt="edgeRightH"
            className="edgeRightH"
            style={{ zIndex: 2 }}
          />
          <img
            src={edges}
            alt="edgeLeft"
            className="edgeLeft"
            style={{ zIndex: 2 }}
          />
          <img
            src={landingPageImg}
            alt="landingPageImg"
            className="landingPageImg"
          />
          <div className="ImgContent">
            <h1 style={{ zIndex: 2, color: "white", fontSize: 25 }}>
              Bienvenue sur le portail
              <br /> Rokhas Analytics
            </h1>
            <p style={{ width: "38%", color: "white", fontSize: 17 }}>
              La plateforme nationale de référence en matière de données
              ouvertes et données partagées relatives aux autorisations à
              caractères territoriale, Rokhas Analytics vous permet d’accéder à
              un large panel de données statistiques, indicateurs de performance
              et tableaux de bords riches et interactifs.
            </p>
          </div>
        </div>
      ) : (
        <div
          className="headContainer"
          style={{
            padding: "0",
            width: "-webkit-fill-available",
            height: "538px",
            position: "relative",
          }}
        >
          <div
            style={{
              borderTop: "10px solid #0284C7",
              background: "#0369A1",
              height: "386px",
              position: "absolute",
              width: "-webkit-fill-available",
              orverflow: "hidden",
              transform: "rotate(175deg) translateY(100px) scale(1.1)",
              zIndex: 1,
            }}
          ></div>
          <img
            src={smile}
            alt="smile"
            className="smile"
            style={{ zIndex: 2 }}
          />
          <img
            src={edges}
            alt="edgeRightH"
            className="edgeRightH"
            style={{ zIndex: 2 }}
          />
          <img
            src={edges}
            alt="edgeLeft"
            className="edgeLeft"
            style={{ zIndex: 2 }}
          />
          <div className="ImgContent">
            <h1 style={{ zIndex: 2, color: "white", textAlign: "center" }}>
              Bienvenue sur le portail Rokhas Analytics
            </h1>
            <p
              style={{
                width: "100%",
                color: "white",
                fontSize: 13,
                textAlign: "center",
              }}
            >
              La plateforme nationale de référence en matière de données
              ouvertes et données partagées relatives aux autorisations à
              caractères territoriale, Rokhas Analytics vous permet d’accéder à
              un large panel de données statistiques, indicateurs de performance
              et tableaux de bords riches et interactifs.
            </p>
          </div>
        </div>
      )}
      <div className="bodyContainer">
        <p className="TextDesc">
          Que vous soyez Citoyen, Acteur économique, Academicien/Chercheur,
          Journaliste, Responsable au sein d’une collectivité territoriale,
          d’une délégation ministérielle ou d'une administration centrale, ...
          Rokhas Analytics vous fourni les données statistiques qui vous
          interessent.
        </p>
        <br />
        <div className="site-card-wrapper homeresponsive">
          <Row gutter={[4, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="flipCard gutter-row"
                bordered={true}
                onMouseEnter={toggleHover}
                onMouseLeave={toggleHover}
                onClick={() => navigate("/OpenData")}
              >
                <div
                  style={{ display: "block", textAlign: "center" }}
                  className="opendataCard"
                >
                  <img src={cloud} alt="cloud" className="cloud" />
                  <p>
                    Données
                    <br /> ouvertes
                  </p>
                </div>
                <div className="hiddenElementCard">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    Données ouvertes (Open data) offrant au grand public et aux
                    spécialistes des statistiques clés sur l’activité économique
                    et urbanistique à l’echelle nationale.
                  </p>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="flipCard gutter-row"
                bordered={true}
                onMouseEnter={toggleHover2}
                onMouseLeave={toggleHover2}
                onClick={() =>
                  (window.location.href = "http://analytics.rokhas.ma")
                }
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <img src={shareData} alt="shareData" className="shareData" />
                  <p>
                    Données
                    <br /> partagées
                  </p>
                </div>
                <div className="hiddenElementCard">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    Données partagées (Shared data) fournissant aux acteurs
                    concernés, des tableaux de bord personnalisés pour le suivi
                    de la performance de leur entités.
                  </p>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="flipCard gutter-row"
                bordered={true}
                onMouseEnter={toggleHover3}
                onMouseLeave={toggleHover3}
                onClick={() => navigate("/GeoData")}
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <img src={geoData} alt="geoData" className="geoData" />
                  <p>
                    Données
                    <br /> géographiques
                  </p>
                </div>
                <div className="hiddenElementCard">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    Géoportail produisant des données spatiales relatives à
                    l’activité urbanistique et économique à l’echelle nationale.
                  </p>
                </div>
              </Card>
            </Col>

            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card
                className="flipCard gutter-row"
                bordered={true}
                onMouseEnter={toggleHover4}
                onMouseLeave={toggleHover4}
                onClick={() =>
                  (window.location.href =
                    "https://rokhas.ma/classement-national")
                }
              >
                <div style={{ display: "block", textAlign: "center" }}>
                  <img
                    src={classement}
                    alt="classement"
                    className="classement"
                  />
                  <p>
                    Classement <br />
                    national
                  </p>
                </div>
                <div className="hiddenElementCard">
                  <p
                    style={{
                      textAlign: "center",
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    “Rokhas Index” est l’indicateur national de référence
                    classant trimestriellement les territoires en terme de
                    performance administrative.
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="borderless-card-wrapper">
          <h1 style={{ color: "#172755", textAlign: "center" }}>
            On n’améliore que ce qu’on mesure !
          </h1>
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <MyCards
                icon="hours"
                title="Processus chronométrés"
                text="Le délai de chaque étape de chaque processus est mesurée de façon systématique, automatique et objective"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <MyCards
                icon="bagcheck"
                title="Responsbailités délimitées"
                text="Chaque action est attribuée  à un et un seul acteur pour une délimitation claire des responsabilités"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <MyCards
                icon="rocket"
                title="Indicateurs  pertinents"
                text="Chaque indicateur choisi, vise la mesure de la performance et par conséquent l’amélioration continue du service rendu"
              />
            </Col>
          </Row>
        </div>

        <div
          className="borderless-card-wrapper"
          style={{ backgroundColor: "white" }}
        >
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={8}>
              <i
                className="fa-regular fa-badge-check"
                style={{ fontSize: "30px", color: "#299729" }}
              ></i>
              <br />
              <h1
                style={{
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "25px",
                  color: "#0369A1",
                }}
              >
                Quelles sont les autorisations trackés?
              </h1>
              <p style={{ color: "#7C828D" }}>
                La nouvelle version du portail Rokhas Analytics a été élargie à
                l’ensemble des types d’autorisations gérées sur la plateforme
                www.rokhas.ma, cela concerne essentiellement les autorisations
                urbanistiques et économiques.
              </p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <Card bordered={false} className="ListAutCards">
                <h6
                  style={{
                    color: "#0369A1",
                    fontWeight: 600,
                    textDecorationLine: "underline",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Autorisations urbanistiques
                </h6>
                <p className="AutList AutListurba">
                  <img src={pc2} alt="logopc" className="logoList" width="15" />
                  <span>Permis de construire</span>
                </p>
                <p className="AutList AutListurba">
                  <img
                    src={rcp2}
                    alt="logorcp"
                    className="logoListLong"
                    width="20"
                  />
                  <p className="textLogoLong">Permis de réception de projets</p>
                </p>
                <p className="AutList AutListurba">
                  <img
                    src={rfc2}
                    alt="logorfc"
                    className="logoList"
                    width="20"
                  />
                  <span>Permis de Réfection</span>
                </p>
                <p className="AutList AutListurba">
                  <img
                    src={ph2}
                    alt="logoph"
                    className="logoListLong"
                    width="20"
                  />
                  <p className="textLogoLong">
                    Permis d'habiter et certificat de conformité
                  </p>
                </p>
                <p className="AutList AutListurba">
                  <img
                    src={dml2}
                    alt="logodml"
                    className="logoList"
                    width="20"
                  />
                  <span>Permis de démolition</span>
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={8}>
              <Card bordered={false} className="ListAutCards">
                <h6
                  style={{
                    color: "#0369A1",
                    fontWeight: 600,
                    textDecorationLine: "underline",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  Autorisations économiques
                </h6>
                <p className="AutList">
                  <span>
                    <img
                      src={afp2}
                      alt="logoafp"
                      className="logoListLong"
                      width="15"
                    />
                  </span>
                  <p className="textLogoLong">
                    Installation d'un dispositif d'affichage publicitaire
                  </p>
                </p>
                <p className="AutList">
                  <img
                    src={anr2}
                    alt="logoranr"
                    className="logoListLong"
                    width="20"
                  />
                  <p className="textLogoLong">
                    Ouverture d’une activité commerciale, artisanale ou
                    industrielle
                  </p>
                </p>
                <p className="AutList">
                  <img
                    src={oan2}
                    alt="logoroan"
                    className="logoListLong"
                    width="25"
                  />
                  <p className="textLogoLong">
                    Occupation du domaine public liée à une activité économique
                  </p>
                </p>
                <p className="AutList">
                  <img
                    src={odprt2}
                    alt="logoadprt"
                    className="logoListLong"
                    width="20"
                  />
                  <p className="textLogoLong">
                    Occupation du domaine public liée aux Télécoms et Travaux
                    publics
                  </p>
                </p>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="borderless-card-wrapper">
          <h1 style={{ color: "#172755", textAlign: "center" }}>
            Responsable d’entités administratives, demandez votre <br />
            accès personnalisé à Rokhas Analytics !
          </h1>
          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <MyCards
                icon="person"
                title="Wali / Gouverneur"
                text="Accès aux indicateurs de performance des territoires sous sa supervision"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <MyCards
                icon="commune"
                title="Président de commune"
                text="Tableaux de suivi de la performance collective et individuelle de sa collectivité"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <MyCards
                icon="entite"
                title="Directeur d’entité"
                text="Indicateur de pilotage de l’entité (Agence urbaine, service deconcentré, délagation, ...)"
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
              <MyCards
                icon="person2"
                title="Ordre professionnel"
                text="Suivi de l’acitivté des constituants (Ordre d’architectes, Topographes, Associations reconnues, ...)"
              />
            </Col>
          </Row>
          <Button
            className="btnDemande"
            style={{ marginLeft: "39%", marginRight: "50%" }}
          >
            <p style={{ color: "white", padding: "3px 0 3px 0" }}>
              Demander votre accès
            </p>
          </Button>
        </div>
      </div>
    </HomeLayout>
  );
}

export default App;
