import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter  } from 'react-router-dom/dist';
import reportWebVitals from './reportWebVitals';
import Myroutes from './routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log("check"+process.env.PUBLIC_URL);
root.render(

//   <div>
//   bienvenue
// </div>
  <BrowserRouter >
  <Myroutes/>
</BrowserRouter >,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
