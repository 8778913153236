import React  from 'react';
import 'antd/dist/antd.min.css';
import './Style.css';
import {Card } from 'antd';
import hours from './svgs_img/24hours.svg'
import bagcheck from './svgs_img/bagcheck.svg'
import rocket from './svgs_img/rocket.svg'
import person from './svgs_img/person.svg'
import commune from './svgs_img/commune.svg'
import entite from './svgs_img/entite.svg'
import person2 from './svgs_img/person2.svg'

function MyCards(props){
const icons = { hours, bagcheck,rocket,person,commune,entite,person2 };
return (
<Card className="borderlessCard"  bordered={false}>
 <div style={{display:"block",textAlign:'center'}} className="myIconsCards">
     <img src={icons[props.icon]} alt="icon" className="icon" width="50px"/>      
        <h3 style={{color:"#172755",paddingTop:"10px"}}>{props.title}</h3>
        <p style={{textAlign:'center',color:'#8794BA',fontSize:"14px"}}>
        {props.text}  
        </p>  
        </div>   
        </Card>

)
}
export default MyCards;
