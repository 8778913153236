import React, { useEffect, useRef, useState, useMemo } from 'react';

import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
//import proj4 from 'proj4';
import { FadeLoader } from 'react-spinners';
import { DateRangePicker } from 'rsuite';



import L from 'leaflet';
import 'leaflet.markercluster';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import 'antd/dist/antd.min.css';
import '../components/Style.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import HomeLayout from "../components/HomeLayout"
import Breadcrumbs from '../components/BreadCrumbs';
import Communepub from '../geojson/newCommunejsonMajdob.json'
import regionpub from '../geojson/regionsmajd.json'
import provincepub from '../geojson/provinceupdate.json'

import Select from 'react-select';
//import useFetchjsons from '../components/Fetchjsons';

const StatMapPC = () => {

  const [prefOptions, setprefOptions] = useState([]);
  const [loader, setloader] = useState(false);
  const [prefecture, setprefecture] = useState('');
  const [selectedOptioncommune, setSelectedOptioncommune] = useState(null);
  const [parametragecomm, setparametragecomm] = useState(false);
  const [clickedLayer, setclickedLayer] = useState(null)
  console.log(prefecture, setclickedLayer)
  const data = regionpub
  const ProvincceData = provincepub
  console.log('test null', ProvincceData)
  const commune = Communepub
  console.log('regions.json 111', commune)
  const getDataa = async () => {

    await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/communeList?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
      "Content-Type": "application/json; charset=utf-8"
    }).then((response) => {
      //console.log(response.data.data)
      var mydata = response.data.data
      //console.log(mydata)
      var table = []
      var obj = {}
      for (let i = 0; i < mydata.length; i++) {
        obj.label = mydata[i]['ARRONDISSEMENT']
        obj.value = mydata[i]['ARRONDISSEMENT']
        table.push(obj);
        obj = {}
      }
      setprefOptions(table)
    })
  };
  useEffect(() => {
    getDataa();
  }, [])
  const handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      setSelectedOptioncommune(null);
      setprefecture('');
    } else {
      setSelectedOptioncommune(selectedOption);
      ////console.log(selectedOption)
      setprefecture(selectedOption['value']);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: 37,
      backgroundColor: '#FFFFFF',
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: 300,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      height: 39,
      marginRight: '-1.5px',
      marginTop: '-1.5px',
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#294774',
    }),
    icon: {
      color: '#FFFFFF',
    },
  };


  const translatedStrings = {
    selectDate: 'Sélectionnez une date',
    confirm: 'Confirmer',

  };
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const handleDateRangeSelect = (value) => {
    setSelectedDateRange(value);

  };
  const handleDateRangeExit = () => {

    setSelectedDateRange(null);
  };


  const [selectedRange] = useState([
    {
      startDate: new Date('2023-01-01 00:00:00'),
      endDate: new Date('2023-12-31 11:22:27'),
      key: 'selection'
    }
  ]);


  //const [zoomlev, setzoomlev] = useState(6);

  const [text, settext] = useState('');
  const [text1, settext1] = useState('');




  const mapApiValueAndLabel = useMemo(() => {
    return {
      "Répartion des dossiers Autorisé par Région et province": "Permis de construire",
      "Répartion des dossiers Autorisé par Région et province PH": "Permis d’habiter",
      "Répartion des dossiers Autorisé par Région et province DML": "Démolition",
      "Répartion des dossiers Autorisé par Région et province RCP": "Réception",
      "Répartion des dossiers Autorisé par Région et province RFC": "Réfection"
    };
  }, []);
  const [selectedOption, setSelectedOption] = useState(`Répartion des dossiers Autorisé par Région et province`);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState(mapApiValueAndLabel[selectedOption]);

  useEffect(
    () => {
      setSelectedOptionLabel(mapApiValueAndLabel[selectedOption]);
    }
    , [selectedOption, mapApiValueAndLabel]
  )

  const [selectedDML, setselectedDML] = useState(`Urbanisme-PC-PH-Service Rendu`)
  const mapRef = useRef(null);
  useEffect(() => {

  })
  useEffect(() => {
    const map = L.map(mapRef.current, {
      center: [30.002517, -14.224784],
      zoom: 6,
      renderer: L.canvas(), // Enable canvas rendering
    });
    var dossiersCommune = {
      nomCommune: "",
      dossiers: [],
      markers: []
    };
    const getData = async () => {
      let dat = ''
      let datt = ''
      let da = ''
      if (selectedDateRange !== null) {
        dat = selectedDateRange[0]?.getFullYear().toString() + '-' +
          String(selectedDateRange[0]?.getMonth() + 1).padStart(2, '0') + '-' +
          String(selectedDateRange[0]?.getDate()).padStart(2, '0')

        datt = selectedDateRange[1]?.getFullYear().toString() + '-' +
          String(selectedDateRange[1]?.getMonth() + 1).padStart(2, '0') + '-' +
          String(selectedDateRange[1]?.getDate()).padStart(2, '0')

        switch (selectedOption) {
          case 'Répartion des dossiers Autorisé par Région et province':
            da = `ZOHO_CRITERIA=("FACT_DOSSIER"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
            break;
          case 'Répartion des dossiers Autorisé par Région et province PH':
            da = `ZOHO_CRITERIA=("FACT_AUTORISATIONPH"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
            break;
          case 'Répartion des dossiers Autorisé par Région et province DML':
            da = `ZOHO_CRITERIA=("FACT_DEMOLITION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
            break;
          case 'Répartion des dossiers Autorisé par Région et province RFC':
            da = `ZOHO_CRITERIA=("FACT_REFECTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
            break;
          case 'Répartion des dossiers Autorisé par Région et province RCP':
            da = `ZOHO_CRITERIA=("FACT_RECEPTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
            break;
          default:
          // ////console.log("no permis");
        }

      }
      await axios.get(`https://analytics.karaz.org/api/` + selectedDML + `/` + selectedOption + `?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
        "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {


        console.log("fetch Region", data);

        if (data) {
          console.log("fetch Region 2", data);
          data && data.features.map(
            jsonData => {
              const jsonProvince = response.data.data.filter(province => {
                return province.Nom_Reg_12 === jsonData.properties.NAME;
              })[0];
              if (jsonProvince !== undefined) {
                jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Count"];
                return jsonData;
              } else {
                jsonData.properties["cartodb_id"] = "0"
                return jsonData;
              }
            }
          )
        }




        //xyz
        L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(map);

        var LOIcon = L.divIcon({
          // iconSize: null,

          html: ` 
          <?xml version="1.0" encoding="utf-8"?>
  
          <svg version="1.1" id="Uploaded to svgrepo.com" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
             width="100" height="100" viewBox="0 0 38 23" xml:space="preserve">
          <style type="text/css">
            .isometric_acht{fill:#F05A28;}
            .isometric_negen{fill:#BE1E2D;}
            .isometric_elf{fill:#b1bab8;}
            .isometric_twaalf{fill:#919e9b;}
            .st0{fill:#464d4a;}
            .st1{fill:#F28103;}
            .st2{fill:#7BD6C4;}
            .st3{fill:#F29227;}
            .st4{fill:#FF7344;}
            .st5{fill:#F8F7C5;}
            .st6{fill:#F5DF89;}
            .st7{fill:#AD9A74;}
            .st8{fill:none;}
            .st9{fill:#BE1E2D;}
            .st10{fill:#F2D76C;}
            .st11{fill:#72C0AB;}
            .st12{fill:#569080;}
          </style>
          <g>
            <path class="isometric_acht" d="M20.995,15.479c0.024-0.712-0.452-1.438-1.488-1.978v-1.321c1.201-0.619,1.548-1.395,1.488-2.054
              V8.606c-0.004-0.668-0.442-1.337-1.315-1.839c-1.767-1.018-4.623-1.023-6.379-0.012c-1.426,0.821-1.295,1.835-1.295,1.835v1.535
              c-0.045,0.645,0.3,1.414,1.488,2.033c0,0,0,1.431,0,1.321c-1.038,0.535-1.513,1.259-1.488,1.971v1.489
              c-0.049,0.702,0.381,1.414,1.315,1.952c1.767,1.018,4.623,1.023,6.379,0.012c0.935-0.539,1.36-1.256,1.295-1.964V15.479z"/>
            <path class="isometric_negen" d="M20.995,15.421v1.519c0.065,0.707-0.36,1.425-1.295,1.964c-1.756,1.011-4.612,1.006-6.379-0.012
              c-0.934-0.538-1.364-1.25-1.315-1.952v-1.535c0.004,0.665,0.432,1.331,1.315,1.839c1.767,1.018,4.623,1.023,6.379,0.012
              C20.578,16.75,20.998,16.086,20.995,15.421z M20.995,8.606c0.013,2.387-5.004,3.362-7.675,1.823
              c-0.883-0.509-1.311-1.175-1.315-1.839v1.535l0,0c-0.045,0.645,0.3,1.414,1.488,2.033v1.782h0.006
              c-0.015,0.456,0.275,0.916,0.881,1.265c1.178,0.679,3.082,0.682,4.253,0.008c0.607-0.35,0.891-0.813,0.866-1.273h0.009v-1.761
              c1.201-0.619,1.548-1.395,1.488-2.054l0,0V8.606z"/>
            <path class="isometric_elf" d="M15.5,19.583v5.537h0.002c0,0,0.997,1.878,0.998,1.88c0.001-0.001,0.999-1.88,1-1.88v-2.828
              c-0.389-1.005-0.881-1.905-1.463-2.661C15.857,19.62,15.678,19.607,15.5,19.583z"/>
            <path class="isometric_twaalf" d="M16.037,19.631c0.582,0.755,1.074,1.656,1.463,2.661v-2.701
              C17.019,19.652,16.527,19.662,16.037,19.631z"/>
          </g>
          </svg>
      `,
          className: 'custom-icon',
          iconSize: [100, 100]
        });
        var communsIsLoaded = false;
        var customIconStyle = document.createElement('style');
        customIconStyle.innerHTML = '.custom-icon{ background-color: transparent; }';
        document.head.appendChild(customIconStyle);

        // const getColor = () => {
        //   console.log('zoom level',map.getZoom())

        //   if(map.getZoom()<=6)
        //    return '#8dd3c7'
        //   else{
        //     return '#6db084'
        //   }

        // };
        let colorclick = ''
        const style = (feature) => {
          // console.log('test for prov',feature)
          return {
            fillColor: feature.properties.color,
            weight: 1,
            //opacity: 1,
            color: '#0269A1',
            //dashArray: '2',
            fillOpacity: 0.5
          };
        };


        // const highlightFeature = (e) => {
        //   const layer = e.target;
        //   layer.setStyle({
        //     weight: 5,
        //     color: '#666',
        //     dashArray: '',
        //     fillOpacity: x
        //   });


        // };
        const GrandPetitProjet1 = async (permis) => {
          let dat = ''
          let datt = ''
          let da = ''
          if (selectedDateRange !== null) {

            dat = selectedDateRange[0]?.getFullYear().toString() + '-' +
              String(selectedDateRange[0]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[0]?.getDate()).padStart(2, '0')

            datt = selectedDateRange[1]?.getFullYear().toString() + '-' +
              String(selectedDateRange[1]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[1]?.getDate()).padStart(2, '0')
            switch (permis) {
              case 'Répartion des dossiers Autorisé par Région et province':
                da = `ZOHO_CRITERIA=("FACT_DOSSIER"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province PH':
                da = `ZOHO_CRITERIA=("FACT_AUTORISATIONPH"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province DML':
                da = `ZOHO_CRITERIA=("FACT_DEMOLITION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RFC':
                da = `ZOHO_CRITERIA=("FACT_REFECTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RCP':
                da = `ZOHO_CRITERIA=("FACT_RECEPTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              default:
            }

          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/test5?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {

              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {

                    if (jsonProvince["CATEGORIE_DESC"] === "Grands projets") {
                      jsonData.properties["Grands_projets"] = jsonProvince["DOSSIER_ID Count"];
                      return jsonData;

                    }
                    if (jsonProvince["CATEGORIE_DESC"] === "Petits projets") {
                      jsonData.properties["Petits_projets"] = jsonProvince["DOSSIER_ID Count"];
                      return jsonData;
                    }

                  }
                  return jsonData;

                }
              )
              geojson.clearLayers();
              console.log('Add data', data)
              geojson.addData(data);


            });
          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province PH') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossierpartypePH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];
                  ////console.log(jsonProvince)
                  if (jsonProvince !== undefined) {

                    if (jsonProvince["typePermis"] === "Certificat de conformité") {
                      jsonData.properties["Certificat_conformité"] = jsonProvince["DOSSIER_ID Count"];

                      return jsonData;

                    }

                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
          //hey reda
          if (permis === 'Répartion des dossiers Autorisé par Région et province RFC') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce mois-ci par region RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["ce_mois_rfc"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_rfc"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RCP') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce mois-ci par region RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["ce_mois_rcp"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_rcp"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province DML') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce mois-ci par region DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["ce_mois_dml"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_dml"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
        };

        const GrandPetitProjet2 = async (permis) => {
          let dat = ''
          let datt = ''
          let da = ''
          // let dph=''
          if (selectedDateRange !== null) {

            dat = selectedDateRange[0]?.getFullYear().toString() + '-' +
              String(selectedDateRange[0]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[0]?.getDate()).padStart(2, '0')

            datt = selectedDateRange[1]?.getFullYear().toString() + '-' +
              String(selectedDateRange[1]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[1]?.getDate()).padStart(2, '0')
            switch (permis) {
              case 'Répartion des dossiers Autorisé par Région et province':
                da = `ZOHO_CRITERIA=("FACT_DOSSIER"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province PH':
                da = `ZOHO_CRITERIA=("FACT_AUTORISATIONPH"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province DML':
                da = `ZOHO_CRITERIA=("FACT_DEMOLITION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RFC':
                da = `ZOHO_CRITERIA=("FACT_REFECTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RCP':
                da = `ZOHO_CRITERIA=("FACT_RECEPTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              default:
              // ////console.log("no permis");
            }

          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RFC') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois precedent par region RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["mois_prec_rfc"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois_prec_rfc"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RCP') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois precedent par region RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["mois_prec_rcp"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois_pre_rcp"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province DML') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois precedent par region DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              data && data.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.Nom_Reg_12 === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    jsonData.properties["mois_prec_dml"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois_prec_dml"] = "0"
                  }
                  return jsonData;
                }
              )
              geojson.clearLayers();
              geojson.addData(data);


            });
          }
        };


        const openPopUp = (e) => {

          const layer = e.target;
          colorclick = layer.feature.properties.NAME;
          let count = 0;
          console.log('colorclick', colorclick)

          map.eachLayer(layer => {
            if (count >= 3) {
              if (layer.feature && layer.feature.properties && layer.feature.properties.NAME !== colorclick) {
                layer.setStyle({

                  color: '#0269A1',
                  weight: 1,
                  fillOpacity: 0.5
                });
              } else {
                if (layer && layer.setStyle && typeof layer.setStyle === 'function') {
                  // Code to set style
                  layer.setStyle({
                    color: '#e61005',
                    weight: 1,
                    fillOpacity: 0
                  });
                } else {
                  console.log('Layer or setStyle method not available.');
                }

              }
            }

            count++;
          });

          const currentZoom = map.getZoom();

          if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            layer.bringToFront();
          }
          var name = "";
          if (currentZoom >= 7) {
            name = " ";
          }

          const props = layer.feature.properties;
          let popupContent;


          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province') {

            popupContent = `
            <div class="popup-container">
              <div class="dossier-info" style=" padding-top: 10px;padding-right: 30px;padding-left: 30px;">
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;gap:'5px'">
                  <strong class="label" style="color: #1C476A; font-size: 14px;">Total Permis délivrés:</strong>
                  <strong class="value" style="color: #1C476A; font-size: 16px;">${props.cartodb_id ? props.cartodb_id : ''}</strong>
                </div>
                <div class="info-line" style="display: flex; justify-content: flex-end; align-items: center; margin-bottom: 5px;gap:20px">
                  <span class="label" style="color: #1C476A; font-size: 14px;width:90">Grands projets:</span>
                  <span class="value" style="color: #1C476A; font-size: 16px;width:25">${props.Grands_projets ? props.Grands_projets : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: flex-end; align-items: center; margin-bottom: 20px;gap:8px">
                  <span class="label" style="color: #1C476A; font-size: 14px;width:90;">Petits projets:</span>
                  <span class="value" style="color: #1C476A; font-size: 16px;width:25">${(props.cartodb_id - props.Grands_projets) ? props.cartodb_id - props.Grands_projets : ''}</span>
                </div>
              </div>
            </div>
            <div style="height:1px;width:80%;background-color:#4A4A4A;margin-bottom:12px">
            </div>
            <div>
            </div>
            
          `+ (map.getZoom() >= 8 ? `<div style="display:flex;justify-content: flex-start;gap:10px ;padding-right: 30px;padding-left: 30px;">
          <div><i class="fa-regular fa-landmark-dome" style="color: #373937;"></i></div>
          <span class="property-name" style="color: #4A4A4A; font-size: 13px;">${name}${props.NAME}</span><br>
          </div>`: `
          <div style="display:flex;justify-content: flex-start;gap:10px ;padding-right: 30px;padding-left: 30px;">
          <div>
          <div><i class="fa-regular fa-city" style="color: #373937;"></i></div>
          </div>
            <span class="property-name" style="color: #4A4A4A; font-size: 13px;">${name}${props.NAME}</span><br>
            </div>

          `) + (map.getZoom() >= 8 ? `<div style="display:flex;justify-content: flex-end" >
          <button class="btn_popup" id="btn_popup_${props.cartodb_id}" >
            Afficher
          </button>
        </div>`: `

        `);

          }

          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province PH') {
            popupContent = `
            <div class="popup-container">
              <div class="dossier-info" style=" padding-top: 10px;padding-right: 30px;padding-left: 30px;">
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;gap:'5px'">
                  <strong class="label" style="color: #1C476A; font-size: 14px;">Total Permis délivrés:</strong>
                  <strong class="value" style="color: #1C476A; font-size: 16px;">${props.cartodb_id ? props.cartodb_id : ''}</strong>
                </div>
                <div class="info-line" style="display: flex; justify-content: flex-end; align-items: center; margin-bottom: 5px;gap:20px">
                  <span class="label" style="color: #1C476A; font-size: 14px;width:90">Certificat de conformité:</span>
                  <span class="value" style="color: #1C476A; font-size: 16px;width:25">${props.Certificat_conformité ? props.Certificat_conformité : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: flex-end; align-items: center; margin-bottom: 20px;gap:8px">
                  <span class="label" style="color: #1C476A; font-size: 14px;width:90;">permis d'habiter:</span>
                  <span class="value" style="color: #1C476A; font-size: 16px;width:25">${(props.cartodb_id - props.Certificat_conformité) ? props.cartodb_id - props.Certificat_conformité : ''}</span>
                </div>
              </div>
            </div>
            <div style="height:1px;width:80%;background-color:#4A4A4A;margin-bottom:12px">
            </div>
            <div>
            </div>
          `+ (map.getZoom() >= 8 ? `<div style="display:flex;justify-content: flex-start;gap:10px ;padding-right: 30px;padding-left: 30px;">
          <div><i class="fa-regular fa-landmark-dome" style="color: #373937;"></i></div>
          <span class="property-name" style="color: #4A4A4A; font-size: 13px;">${name}${props.NAME}</span><br>
          </div>`: `
          <div style="display:flex;justify-content: flex-start;gap:10px ;padding-right: 30px;padding-left: 30px;">
          <div>
          <div><i class="fa-regular fa-city" style="color: #373937;"></i></div>
          </div>
            <span class="property-name" style="color: #4A4A4A; font-size: 13px;">${name}${props.NAME}</span><br>
            </div>

          `) +
              (map.getZoom() >= 8 ? `<div style="display:flex;justify-content: flex-end" >
         <button class="btn_popup" id="btn_popup_${props.cartodb_id}" >
           Afficher
         </button>
       </div>`: `

       `);
          }
          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province DML') {

            popupContent = `
            <div class="popup-container" style="background-color: white; color: black; padding: 10px; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
              <strong class="property-name" style="color: #2978AA; font-size: 16px;">${name}${props.NAME}</strong><br>
              <div class="dossier-info" style="margin-top: 10px; border-top: 1px solid #ccc; padding-top: 10px;">
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Nombre dossiers:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.cartodb_id ? props.cartodb_id : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">ce mois-ci:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.ce_mois_dml ? props.ce_mois_dml : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Mois précédent:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.mois_prec_dml ? props.mois_prec_dml : ''}</span>
                </div>
              </div>
            </div>
          `;
          }
          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province RFC') {

            popupContent = `
            <div class="popup-container" style="background-color: white; color: black; padding: 10px; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
              <strong class="property-name" style="color: #2978AA; font-size: 16px;">${name}${props.NAME}</strong><br>
              <div class="dossier-info" style="margin-top: 10px; border-top: 1px solid #ccc; padding-top: 10px;">
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Nombre dossiers:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.cartodb_id ? props.cartodb_id : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">ce mois-ci refection:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.ce_mois_rfc ? props.ce_mois_rfc : ''}</span>
                </div>
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Mois précédent: </span>
                  <span class="value" style="color: green; font-size: 16px;">${props.mois_prec_rfc ? props.mois_prec_rfc : ''}</span>
                </div>
              </div>
            </div>
          `;
          }
          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province RCP') {

            popupContent = `

            <div class="popup-container" style="background-color: white; color: black; padding: 10px; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);">
              <strong class="property-name" style="color: #2978AA; font-size: 16px;">${name}${props.NAME}</strong><br>
              <div class="dossier-info" style="margin-top: 10px; border-top: 1px solid #ccc; padding-top: 10px;">
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Nombre dossiers:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.cartodb_id ? props.cartodb_id : ''}</span>
                </div>
                
                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Ce mois reception:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.ce_mois_rcp ? props.ce_mois_rcp : ''}</span>
                </div>

                <div class="info-line" style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 5px;">
                  <span class="label" style="color: #888; font-size: 14px;">Mois précédent:</span>
                  <span class="value" style="color: green; font-size: 16px;">${props.mois_prec_rcp ? props.mois_prec_rcp : ''}</span>
                </div>
              </div>
            </div>
          `;

          }

          layer.bindPopup(popupContent).openPopup();
          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province' && map.getZoom() >= 8) {
            document.body.addEventListener('click', async function (event) {
              if (event.target.id === `btn_popup_${props.cartodb_id}`) {
                layer.closePopup();
                var layerbnds = layer.getBounds();
                if (layerbnds.isValid()) {
                  map.fitBounds(layerbnds, { maxZoom: 18 });
                }
                await testclick(props.NAME, layer, selectedOption);
              }
            });



          }
          if (selectedOption === 'Répartion des dossiers Autorisé par Région et province PH' && map.getZoom() >= 8) {
            document.body.addEventListener('click', async function (event) {
              if (event.target.id === `btn_popup_${props.cartodb_id}`) {
                layer.closePopup();
                var layerbnds = layer.getBounds();
                if (layerbnds.isValid()) {
                  map.fitBounds(layerbnds, { maxZoom: 18 });
                }
                await testclick(props.NAME, layer, selectedOption);
              }
            });

          }
        }


        // const resetHighlight = (e) => {
        //   geojson.resetStyle(e.target);
        // };
        const onEachFeature = async (feature, layer) => {

          layer.on({

            click: openPopUp,
            // mouseover: highlightFeature,
            //mouseout: resetHighlight,
          });
          // layer.on({
          //   mouseover: testclick,
          // });

        };

        const zoomThreshold = 6;

        const geojson = L.geoJSON(data, {

          style: style,
          onEachFeature: onEachFeature,
        }).addTo(map);
        function removeAccents(input) {
          return input.replace(/[éèêëÉÈÊË]/g, 'e')
            .replace(/[ôÖ]/g, 'o')
            .replace(/[üÜ]/g, 'u')
            .replace(/[ïÏ]/g, 'i')
            .replace(/[àâÀÂ]/g, 'a')
            .replace(/'/g, "''");
        }
        console.log('hta rja3');
        const dossierParcategory = async () => {
          await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers autorisé par commune par categorie projet?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {

            commune && commune.features.map(
              jsonData => {
                const jsonProvince = response.data.data.filter(province => {

                  return province.ARRONDISSEMENT === jsonData.properties.NAME;
                })[0];
                if (jsonProvince !== undefined) {

                  if (jsonProvince["CATEGORIE_DESC"] === "Grands projets") {
                    jsonData.properties["Grands_projets"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;

                  }
                  if (jsonProvince["CATEGORIE_DESC"] === "Petits projets") {
                    jsonData.properties["Petits_projets"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }



                }
                return jsonData;

              }
            )
          });
        }
        const dossierParcategoryPH = async () => {
          await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers autorisé par commune par categorie projetPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {

            commune && commune.features.map(
              jsonData => {
                const jsonProvince = response.data.data.filter(province => {

                  return province.COMMUNE === jsonData.properties.NAME;
                })[0];
                if (jsonProvince !== undefined) {

                  if (jsonProvince["NATURE"] === "Permis d'habiter") {
                    jsonData.properties["Permis_habiter"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    console.log(jsonData)
                    return jsonData;

                  }
                  if (jsonProvince["NATURE"] === "Certificat de conformité") {
                    jsonData.properties["Certificat_conformité"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }



                }
                return jsonData;

              }
            )
          });
        } 
        const addCommune = async (label) => {
          setloader(true)
          await dossierParcategory();
          await dossierParcategoryPH();
          await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers autorisé par commune?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            commune && commune.features.map(
              jsonData => {

                const jsonProvince = response.data.data.filter(province => {
                  return province.ARRONDISSEMENT === jsonData.properties.NAME;
                })[0];
                if (jsonProvince !== undefined) {
                  jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                  return jsonData;
                }
                return jsonData;
              }
            )

            geojson.clearLayers();
            geojson.addData(commune);
            var i = 1;
            console.log('testcomm');

            map.eachLayer(layer => {
              i++
              if (i <= 4) {
                return;
              }
              // console.log('layer khdam ==>', layer.feature.properties.NAME);
              if (layer.feature.properties.NAME === label) {
                layer.setStyle({
                  color: 'red',
                  fillOpacity: 0.5
                });
                const layerBounds = layer.getBounds();
                map.fitBounds(layerBounds);
                layer.fireEvent('click');
              } else {

                layer.setStyle({
                  color: 'white',
                  fillOpacity: 0.5

                });
              }
            })
          });
          communsIsLoaded = true;
          setloader(false)

        }
        const addCommune1 = async () => {
          await dossierParcategory();
          await dossierParcategoryPH();
          await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers autorisé par commune?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            commune && commune.features.map(
              jsonData => {

                const jsonProvince = response.data.data.filter(province => {


                  return province.ARRONDISSEMENT === jsonData.properties.NAME;
                })[0];
                if (jsonProvince !== undefined) {
                  jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                  return jsonData;
                }
                return jsonData;
              }
            )

            geojson.clearLayers();
            console.log('commune111', commune)
            geojson.addData(commune);

            //console.log('testcomm');

          });
          communsIsLoaded = true;
        }




        const provincedata = async (permis) => {
          let dat = ''
          let datt = ''
          let da = ''
          if (selectedDateRange !== null) {
            dat = selectedDateRange[0]?.getFullYear().toString() + '-' +
              String(selectedDateRange[0]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[0]?.getDate()).padStart(2, '0')

            datt = selectedDateRange[1]?.getFullYear().toString() + '-' +
              String(selectedDateRange[1]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[1]?.getDate()).padStart(2, '0')
            switch (selectedOption) {
              case 'Répartion des dossiers Autorisé par Région et province':
                da = `ZOHO_CRITERIA=("FACT_DOSSIER"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province PH':
                da = `ZOHO_CRITERIA=("FACT_AUTORISATIONPH"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province DML':
                da = `ZOHO_CRITERIA=("FACT_DEMOLITION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RFC':
                da = `ZOHO_CRITERIA=("FACT_REFECTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RCP':
                da = `ZOHO_CRITERIA=("FACT_RECEPTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              default:
              // ////console.log("no permis");
            }

          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers par province?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {
                    jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });
          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province PH') {

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/dossiers par province ph?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {
                    jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province DML') {

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/dossiers par province dml?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {
                    jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RFC') {

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/dossiers par province rfc?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {
                    jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RCP') {

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/dossiers par province rcp?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {
                    jsonData.properties["cartodb_id"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
        };

        const GrandPetitProjet = async (permis) => {
          let dat = ''
          let datt = ''
          let da = ''
          if (selectedDateRange !== null) {
            dat = selectedDateRange[0]?.getFullYear().toString() + '-' +
              String(selectedDateRange[0]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[0]?.getDate()).padStart(2, '0')

            datt = selectedDateRange[1]?.getFullYear().toString() + '-' +
              String(selectedDateRange[1]?.getMonth() + 1).padStart(2, '0') + '-' +
              String(selectedDateRange[1]?.getDate()).padStart(2, '0')

            switch (selectedOption) {
              case 'Répartion des dossiers Autorisé par Région et province':
                da = `ZOHO_CRITERIA=("FACT_DOSSIER"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province PH':
                da = `ZOHO_CRITERIA=("FACT_AUTORISATIONPH"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province DML':
                da = `ZOHO_CRITERIA=("FACT_DEMOLITION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RFC':
                da = `ZOHO_CRITERIA=("FACT_REFECTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              case 'Répartion des dossiers Autorisé par Région et province RCP':
                da = `ZOHO_CRITERIA=("FACT_RECEPTION"."DATEDELIVRANCE" BETWEEN '` + dat + ` 00:00:00' AND '` + datt + ` 11:22:27.0' )`
                break;
              default:
              // ////console.log("no permis");
            }
          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/grandPetitProject?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {

              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {

                    if (jsonProvince["CATEGORIE_DESC"] === "Grands projets") {
                      jsonData.properties["Grands_projets"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                      return jsonData;

                    }
                    if (jsonProvince["CATEGORIE_DESC"] === "Petits projets") {
                      jsonData.properties["Petits_projets"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                      return jsonData;
                    }



                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);
            });
          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province PH') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/grandPetitProjectPH?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {

              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];
                  if (jsonProvince !== undefined) {

                    if (jsonProvince["typePermis"] === "Certificat de conformité") {
                      jsonData.properties["Certificat_conformité"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                      return jsonData;

                    }
                    if (jsonProvince["typePermis"] === "Permis d'habiter") {
                      jsonData.properties["Permis_habiter"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                      return jsonData;
                    }



                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }

          if (permis === 'Répartion des dossiers Autorisé par Région et province DML') {

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce-mois par province DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log(jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["ce_mois_dml"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_dml"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });

            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois précédent par province DML?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log(jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["mois_prec_dml"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois_prec_dml"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RCP') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce-mois par province RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log(jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["ce_mois_rcp"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_rcp"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois précédent par province RCP?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log(jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["mois_prec_rcp"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois_prec_rcp"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });


          }
          if (permis === 'Répartion des dossiers Autorisé par Région et province RFC') {
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/ce-mois par province RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log('ayeeh', jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["ce_mois_rfc"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["ce_mois_rfc"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });
            await axios.get(`https://analytics.karaz.org/api/Urbanisme-DML_RFC_RCP/mois précédent par province RFC?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&` + da, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              ProvincceData && ProvincceData.features.map(
                jsonData => {
                  const jsonProvince = response.data.data.filter(province => {
                    return province.PREFECTURE === jsonData.properties.NAME;
                  })[0];

                  if (jsonProvince !== undefined) {
                    ////console.log('ayeeh', jsonProvince["DOSSIER_ID Nombre distinct"])
                    jsonData.properties["mois_prec_rfc"] = jsonProvince["DOSSIER_ID Nombre distinct"];
                    return jsonData;
                  } else {
                    jsonData.properties["mois précédent par province RFC"] = "0"
                  }
                  return jsonData;

                }
              )

              geojson.clearLayers();
              geojson.addData(ProvincceData);

            });

          }


        };

        const DossiersPoint = async () => {

        }

        const switchGeoJSONData = async () => {
          const currentZoom = map.getZoom();

          if (currentZoom > zoomThreshold && currentZoom < 8) {
            await provincedata(selectedOption);
            await GrandPetitProjet(selectedOption)
            //console.log(selectedOption)

          }
          if ((currentZoom >= 8 && currentZoom <= 9)) {

            if (currentZoom === 14) {
              return
            }
            console.log('condition', parametragecomm === false && selectedOptioncommune === null)
            console.log('parametragecomm', parametragecomm === false)
            console.log('selectedOptioncommune', selectedOptioncommune === null)
            if ((parametragecomm === false && selectedOptioncommune === null) || (parametragecomm === true && selectedOptioncommune === null)) {
              await addCommune1();
            }



            //await dossierParcategory();

          }
          if (currentZoom > 9) {
            await DossiersPoint();


          }


          if (currentZoom <= 5) {
            await GrandPetitProjet1(selectedOption);
            await GrandPetitProjet2(selectedOption);

          }

        };
        const switchGeoJSONData1 = async () => {
          const currentZoom = map.getZoom();

          if (currentZoom > zoomThreshold && currentZoom < 8) {
            await provincedata(selectedOption);
            await GrandPetitProjet(selectedOption)
            //console.log(selectedOption)

          }
          if (selectedOptioncommune) {
            console.log("FAFA11");
            if (!communsIsLoaded) {
              setparametragecomm(true)
              await addCommune(selectedOptioncommune['label']);

            }


            //await dossierParcategory();

          }
          if (currentZoom > 9) {
            await DossiersPoint();


          }


          if (currentZoom <= 5) {
            await GrandPetitProjet1(selectedOption);
            await GrandPetitProjet2(selectedOption);

          }

        };
        /*   const drawpoly = (inputString) => {
             // Define Lambert Zone 1 projection parameters (replace with your actual parameters)
             const zone2Params = {
               lat1: 28.1063294800,
               lat2: 31.2932791054,
               lat0: 29.7,
               lon0: -5.4,
               x0: 500000,
               y0: 300000,
               ellipsoid: 'clrk80'
             };
           
             // Register Lambert Zone 2 projection with proj4
             proj4.defs('lambertZone2', `+proj=lcc +lat_1=${zone2Params.lat1} +lat_2=${zone2Params.lat2} +lat_0=${zone2Params.lat0} +lon_0=${zone2Params.lon0} +x_0=${zone2Params.x0} +y_0=${zone2Params.y0} +ellps=${zone2Params.ellipsoid} +towgs84=0,0,0,0,0,0,0 +units=m +no_defs`);
           
             // Split the input string into segments based on 'x' and 'y' labels
             const segments = inputString.split(/x\d+ : |; y\d+ : /);
             segments.shift(); // Remove the empty first element
           
             // Initialize arrays to store x and y coordinates
             const xCoordinates = [];
             const yCoordinates = [];
           
             // Iterate through the segments and extract numeric values
             for (let i = 0; i < segments.length; i += 2) {
               const xValue = parseFloat(segments[i]);
               const yValue = parseFloat(segments[i + 1]);
           
               // Check if xValue and yValue are valid numbers
               if (!isNaN(xValue) && !isNaN(yValue)) {
                 xCoordinates.push(xValue);
                 yCoordinates.push(yValue);
               }
             }
           
             // Convert Lambert Zone 1 coordinates to WGS84
             const wgs84Coordinates = xCoordinates.map((x, index) => {
               const y = yCoordinates[index];
               const lambertZone1Coords = proj4('lambertZone2', 'WGS84', [x, y]);
               return lambertZone1Coords;
             });
           
             // Create a Leaflet map
        
             // Define the polygon coordinates as an array of LatLng objects
             const polygonPoints = wgs84Coordinates.map(coords => L.latLng(coords[1], coords[0]));
           
             // Create a polygon and add it to the map
             const polygon = L.polygon(polygonPoints).addTo(map);
           
             // Fit the map to the polygon bounds
             map.fitBounds(polygon.getBounds());
           };
           */

        const testclick = async (communename, lay, optionselected) => {
          let permi;
          switch (optionselected) {
            case 'Répartion des dossiers Autorisé par Région et province':
              permi = `dossiers autorisé`
              break;
            case 'Répartion des dossiers Autorisé par Région et province PH':
              permi = `DossierPH_LangLat`
              break;
            default:
          }

          setloader(true)
          map.eachLayer(layer => {
            
            if (layer instanceof L.MarkerClusterGroup) {
             
              map.removeLayer(layer);
              
              layer.clearLayers();
            }
          });
          const layerBounds = lay.getBounds();
          console.log('Layer', layerBounds)

          var realcommune;
          var comuneNa = removeAccents(communename);

          await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/communeList?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA=(ARRONDISSEMENT LIKE '%25` + comuneNa.toUpperCase() + `%25')`, {
            "Content-Type": "application/json; charset=utf-8"
          }).then((response) => {
            console.log('test Error', response.data.data)
            realcommune = response.data.data[0].ARRONDISSEMENT;
          });
          if (map.getZoom() >= 8) {
            realcommune = removeAccents(realcommune);

            if (dossiersCommune.dossiers.length > 0 && dossiersCommune.markers.length > 0) {
              console.log('dossiersCommune.dossiers.length', dossiersCommune.dossiers.length)
              dossiersCommune.dossiers = [];
              dossiersCommune.markers.forEach(marker => {
                map.removeLayer(marker);
              })
              dossiersCommune.markers = [];
            }


            await axios.get(`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/` + permi + `?ZOHO_ERROR_FORMAT=JSON&ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_CRITERIA="ARRONDISSEMENT"='` + realcommune + `'`, {
              "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
              console.log('dossiersCommune.dossiers', dossiersCommune.dossiers)
              dossiersCommune.dossiers = response.data.data;
              console.log('Leaflet:', L);
              console.log('MarkerCluster:', L.markerClusterGroup);
              const markerClusterGroup = L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                  const childCount = cluster.getChildCount();
                  console.log(childCount)
                  const opacity = 0.7;
                  function getColor(count) {
                    // Customize this logic to set different colors based on the count
                    if (count < 50) {
                      return '#294774';
                    } else if (count < 100) {
                      return '#3287AA';
                    } else {
                      return 'green';
                    }
                  }
                  function getColor1(count) {
                    return 'white';
                  }

                  // Use the getColor function to dynamically set the background color

                  const backgroundColor = getColor(childCount);
                  const backgroundColor1 = getColor1(childCount);
                  return L.divIcon({
                    html: `<div style="background-color: ${backgroundColor1}; opacity: ${opacity}; border-radius: 50%; width: 35px; height: 35px; display: flex; justify-content: center; align-items: center; color: white; font-weight: bold;">
                    <div style="background-color: ${backgroundColor};  border-radius: 50%; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; color: white; font-weight: bold;">${childCount}+</div>
                    </div>`,
                    className: 'custom-cluster-icon',
                    iconSize: [30, 30],
                  });
                },
              });
              console.log('markerClusterGroup', markerClusterGroup)
              dossiersCommune.dossiers.forEach((dossier, index) => {

                const markers = L.marker([dossier.LATITUDE, dossier.LONGITUDE], { icon: LOIcon })
                  .bindPopup(`
                <div class="popup">
              <div class="popup-content">
                  <div class="data-item" >
                     <div class="prefixinfo"><span class="label-data-item">Référence  </span>
                     </div> <div class="valeur"> ${dossier.REFERENCE}</div>
                  </div>
                  <div class="data-item">
                  <div class="prefixinfo"> <span class="label-data-item">N° Permis </span> </div><div class="valeur"> ${dossier.N_PERMIS}</div>
                  </div>
                  <div class="data-item">
                  <div class="prefixinfo"> <span class="label-data-item">Date délivrance  </span> </div>
                 <div  class="valeur"> ${dossier.DATE_DELIVRANCE}</div>
              </div>
                  <div class="data-item">
                  <div class="prefixinfo">  <span class="label-data-item">Titre foncier </span> </div>
                <div  class="valeur"> ${dossier.TITRE_FONCIER}</div>
              </div>
                  <div class="data-item">
                  <div class="prefixinfo"><span class="label-data-item">Surface</span> </div>
                      <div class="valeur">${dossier.S_TERRAIN} m&sup2;</div>
                  </div>
                  <div class="data-item">
                  <div class="prefixinfo">  <span class="label-data-item">Nature </span> </div>
                    <div class="valeur">${dossier.NATURE_DESC}</div>
                  </div>
                  <div class="data-item">
                  <div class="prefixinfo">  <span class="label-data-item">Consistance  </span> </div>
                  <div class="valeur">${dossier.CONSISTENCE}</div>
                    
                  </div>
               
                  <hr style="border: 1px solid #333; margin: 10px 0;width:40%">
                  <div class="data-item">
                  <div><i class="fa-regular fa-city" style="color: #373937;"></i></div>
                  <span class="label"> ${dossier.PREFECTURE} </span> 
              </div>
              <div class="data-item">
              <div><i class="fa-regular fa-landmark-dome" style="color: #373937;"></i></div>
              <span class="label">${dossier.ARRONDISSEMENT} </span> 
          </div>
          <div class="data-item">
          <div><i class="fa-regular fa-map-location" style="color: #646568;"></i></div>
          <span class="label">${dossier.adp} </span> 
      </div>
              </div>
              <div style="display:flex;justify-content: flex-end" >
        </div>
          </div>`);
                if (layerBounds.contains(markers.getLatLng())) {
                  markerClusterGroup.addLayer(markers);
                }
                dossiersCommune.markers.push(markers);
                //drawpoly('x0 : 108372.85; y0 : 374468.65x1 : 108379.62; y1 : 374461.25x2 : 108372.67; y2 : 374454.16x3 : 10836')
              })
              map.addLayer(markerClusterGroup);
            });
            dossiersCommune.nomCommune = "";

          }
          setloader(false)

        }
        map.on('load', GrandPetitProjet1(selectedOption));
        // map.on('click',(e)=>{
        //   if (dossiersCommune.nomCommune ==="" && map.getZoom() >=8) {
        //     //console.log(e.target.feature.properties.NAME);
        //     dossiersCommune.nomCommune = e.target.feature.properties.NAME;
        //   }
        // });
        map.on('zoomend', switchGeoJSONData);



        map.whenReady(async () => {
          await GrandPetitProjet1(selectedOption);
          await GrandPetitProjet2(selectedOption);

        });

        switchGeoJSONData1();

      })
    };
    // if (selectedOptioncommune) {
    //   console.log("commune", selectedOptioncommune['label']);
    //   map.setZoom(9);

    //   setTimeout(() => {
    //     var i = 1
    //     map.eachLayer(layer => {
    //       i++
    //       if (i <= 4) {
    //         return;
    //       }

    //       console.log('layer khdam ==>', layer.feature.properties.NAME);
    //         if (layer.feature.properties.NAME ===selectedOptioncommune['label']) {
    //           layer.setStyle({
    //             color: 'red',  
    //             fillOpacity: 0.5 
    //           });
    //           const layerBounds = layer.getBounds();
    //            map.fitBounds(layerBounds);
    //            layer.fireEvent('click');
    //          }else {

    //           layer.setStyle({
    //             color: 'white', 
    //             fillOpacity: 0.5    

    //           });}          
    //     })
    //   }, 4000);
    // }

    getData();


    return () => {
      map.remove();
    };

  }, [ProvincceData, selectedOption, selectedDML, selectedDateRange, selectedOptioncommune, parametragecomm, clickedLayer, data, commune]);




  const handleDropdownChange = (value) => {
    setShowMenu(false);
    document.querySelector(".dropdown-menu").blur()
    if (value === 'Répartion des dossiers Autorisé par Région et province DML' || value === 'Répartion des dossiers Autorisé par Région et province RFC' || value === 'Répartion des dossiers Autorisé par Région et province RCP') {
      setSelectedOption(value);
      setselectedDML(`Urbanisme-DML_RFC_RCP`)
    } else {
      setSelectedOption(value);
      setselectedDML(`Urbanisme-PC-PH-Service Rendu`)
    }


  };
  // const handleDropdownChange1 = (event) => {
  //setzoomlev(parseInt(event.target.value))

  // };






  // const handleButtonClick = () => {
  //   setOpen(false);
  // };




  useEffect(
    () => {

      settext('Plage sélectionnée : De ' + selectedRange[0]?.startDate.getFullYear().toString() + '-' +
        String(selectedRange[0]?.startDate.getMonth() + 1).padStart(2, '0') + '-' +
        String(selectedRange[0]?.startDate.getDate()).padStart(2, '0'))
      settext1(' à ' + selectedRange[0]?.endDate.getFullYear().toString() + '-' +
        String(selectedRange[0]?.endDate.getMonth() + 1).padStart(2, '0') + '-' +
        String(selectedRange[0]?.endDate.getDate()).padStart(2, '0')
      )
      console.log(text, text1)
    }, [selectedRange, text1, text]
  )
  const [isphone, setisphone] = useState(false)
  const [isUp, setisUp] = useState(false)
  useEffect(() => {
    if (window.innerWidth <= 768) {
      const toDown = document.querySelectorAll(".filter-container");
      toDown.forEach(e => {
        e.style.display = 'flex';
      })
      const toUpp = document.querySelectorAll(".filter-container1");
      toUpp.forEach(e => {

        e.style.height = '38%';
        setisphone(true)
      })
      setisUp(false);

    }
  }, [])



  const elmnts = document.querySelectorAll(".filterupContainer");
  elmnts.forEach(e => {
    e.addEventListener('click', () => {
      if (isUp) {
        if (window.innerWidth <= 768) {
          const toDown = document.querySelectorAll(".filter-container");
          toDown.forEach(e => {
            e.style.display = 'flex';
          })
          const toUpp = document.querySelectorAll(".filter-container1");
          toUpp.forEach(e => {

            e.style.height = '38%';
            setisphone(true)
          })
          setisUp(false);

        } else {
          const toDown = document.querySelectorAll(".filter-container");
          toDown.forEach(e => {
            e.style.display = 'flex';
          })
          const toUpp = document.querySelectorAll(".filter-container1");
          toUpp.forEach(e => {

            e.style.height = '12%';
          })
          setisUp(false);
        }

      } else {
        const toUp = document.querySelectorAll(".filter-container");
        toUp.forEach(e => {
          e.style.display = 'none';
        })
        const toUpp = document.querySelectorAll(".filter-container1");
        toUpp.forEach(e => {
          e.style.height = '2%';
        })

        setisUp(true);
      }
    })
  })

  const [showMenu, setShowMenu] = useState(false);



  return (
    <HomeLayout>
      <div style={{ margin: '0 5% 0 5%', width: '90%', height: '850px', display: 'flex', flexDirection: 'column', position: 'relative' }}>
        <Breadcrumbs home="Géoportail" icon="pc2" text="Autorisations urbanistiques" />

        <div style={{ position: 'relative', display: 'flex', width: '92%', height: '100%', flexDirection: 'column', paddingTop: '20px' }}>
          <div style={{ position: 'absolute', zIndex: '9000000000', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}><FadeLoader color="#8BB2AA" height={31} width={12} margin={20} radius={11} loading={loader} /></div>
          <div ref={mapRef} className='mapp' >
          </div>
          <div className='filter-container1' >
            <div className='filter-container'>

              {/* <div style={{ display: 'flex', flexDirection: 'row', width: '230px' }}>
                <input type='search' placeholder="Commune,Préfecture" className='inputsearch-map'>
                </input>
                <div >
                  <button className='btn-searchbar'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" fill='white'><path d="M408 120c0 54.6-73.1 151.9-105.2 192c-7.7 9.6-22 9.6-29.6 0C241.1 271.9 168 174.6 168 120C168 53.7 221.7 0 288 0s120 53.7 120 120zm8 80.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503V200.4zM137.6 138.3c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3l122.6-49zM327.8 332c13.9-17.4 35.7-45.7 56.2-77V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0zM288 152a40 40 0 1 0 0-80 40 40 0 1 0 0 80z" /></svg>
                  </button>
                </div>


              </div> */}
              <div style={{ width: '238px' }}>
                <Select placeholder="commune" options={prefOptions} styles={customStyles} onChange={handleSelectChange} isClearable={true} isMulti={false} components={{
                  DropdownIndicator: (props) => (
                    <div style={customStyles.dropdownIndicator(props)}><i className="far fa-map-location" style={customStyles.icon}></i></div>
                  ),
                }} />
              </div>



              <button className="dropdown-menu" style={{ position: "relative",backgroundColor:'white',color:'#979797' }} onFocus={() => { setShowMenu(true) }} onBlur={() => { setShowMenu(false) }} >
                {selectedOptionLabel}
                <span className='permis-select-icon'>
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
                {
                  <div className="custom-dropdown" style={{ display: showMenu ? "unset" : "none" }}>
                    <div onClick={() => { handleDropdownChange("Répartion des dossiers Autorisé par Région et province") }}>Permis de construire</div>
                    <div onClick={() => { handleDropdownChange("Répartion des dossiers Autorisé par Région et province PH") }}>Permis d’habiter</div>
                    <div onClick={() => { handleDropdownChange("Répartion des dossiers Autorisé par Région et province DML") }}>Démolition</div>
                    <div onClick={() => { handleDropdownChange("Répartion des dossiers Autorisé par Région et province RCP") }}>Réception</div>
                    <div onClick={() => { handleDropdownChange("Répartion des dossiers Autorisé par Région et province RFC") }}>Réfection</div>
                  </div>
                }
              </button>

              {/* <div className="dropdown-menu" style={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: '10px', right: '10px', zIndex: 1000, backgroundColor: '#F0F2F5' }}> */}
              {/* <div className="dropdown-menu" style={{position:"relative"}} >
                <span className='permis-select-icon'>
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
                <select className="custom-dropdown" value={selectedOption} onChange={handleDropdownChange} onClick={handleDropdownChange} >
                  <option value="Répartion des dossiers Autorisé par Région et province">Permis de construire</option>
                  <option value="Répartion des dossiers Autorisé par Région et province PH">Permis d’habiter</option>
                  <option value="Répartion des dossiers Autorisé par Région et province DML">Démolition</option>
                  <option value="Répartion des dossiers Autorisé par Région et province RCP">Réception</option>
                  <option value="Répartion des dossiers Autorisé par Région et province RFC">Réfection</option>
                </select>
              </div> */}

              <div style={{ width: '240px', zIndex: '0' }}>
                <DateRangePicker

                  placeholder="Date délivrance "
                  locale={translatedStrings}
                  style={{ width: '100%' }}
                  character=' : '
                  ranges={['']}
                  placement='bottomEnd'
                  showOneCalendar={isphone}
                  onOk={handleDateRangeSelect}
                  onClean={handleDateRangeExit}
                />
                {selectedDateRange && (
                  <div style={{ position: 'absolute' }}>
                    <span style={{ color: 'white' }}>

                      Du {selectedDateRange[0].toLocaleDateString()} {' Au '}
                      {selectedDateRange[1].toLocaleDateString()}
                    </span>
                  </div>
                )}
              </div>

            </div>

            <div className='filterupContainer'>
              <span style={{ color: 'white', display: 'flex', gap: '3px', position: 'relative', top: '2px' }}>{!isUp ? (<div className='filter-down' > <IoIosArrowDropup /> </div>) : <div className='filter-down'><IoIosArrowDropdown /></div>}  Filters de recherche</span>
            </div>
          </div>
        </div>


      </div>
    </HomeLayout>
  );
};
export default StatMapPC;