import { PieChart, Pie, Cell } from 'recharts';
import React, { useEffect, useState  } from 'react'
import axios from 'axios'


function PieChartWithNeedle(props) {

    const [Data, setData] = useState([]);

   
    useEffect(() => {
        const getData = async () => {
            if(props.pref){
                await axios.get(`https://analytics.karaz.org/api/rokhasapiweb/Retard moyen pc?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="prefecture" ='` + props.pref + `'`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                setData(response.data.data)
            })
    
            }else{
                await axios.get(`https://analytics.karaz.org/api/rokhasapiweb/Retard moyen pc?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`, {
                "Content-Type": "application/json; charset=utf-8"
            }).then((response) => {
                console.log(response.data.data)
                setData(response.data.data)
                console.log(response.data.data)
            })
    
            }
            
        };
        getData();
    }, [props.pref]);
    var x=0
    if (Data.length === 0) {

    }
    if (Data.length !== 0) {
         x=Data[0]['Avg dureemoy']
    }
    
const RADIAN = Math.PI / 180;
const data = [
    { name: 'A', value: 5, color: 'rgba(115, 179, 96, 0.28)' },
    { name: 'd', value: 20, color: 'rgba(76, 196, 162, 0.22)' },
    { name: 'c', value: 20, color: 'rgba(115, 179, 96, 0.28)' },
    { name: 'd', value: 20, color: 'rgba(76, 196, 162, 0.22)' },

  
];
//const cx = 150;
//const cy = 200;
const iR = 50;
const oR = 100;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
    ];
};
  
        return (

            <div>
            <p class="font-sans md:font-serif font-medium pl-8 pt-10 ">Retard moyen/j émission PV</p>
            <PieChart width={700} height={170}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx={110}
                    cy={120}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#8884d8"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                {needle(value, data, 110, 120, iR, oR, '#d0d000')}
            </PieChart>
            <div class="pl-24" >
            <h1 class="">{x}</h1>
            </div>
           
            </div>
                );
  }
export default PieChartWithNeedle
