import { PieChart, Pie, Sector,ResponsiveContainer,Cell } from 'recharts';
import React, { useCallback, useState,useEffect } from "react";
import axios from 'axios'

const COLORS = ["#2FC523", "#017310"];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 10;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 4}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{payload.value}</text>
      <text
        x={ex +5 }
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


export const CustomActiveShapePieChart = (props) => {
  const [Data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if(props.pref){
        var valeur;
        if(props.com){
           valeur = props.com
        if(valeur.includes("'")){
            valeur = valeur.replace(/'/g, "''");
        }
        var apipre;
        if(apipre){
          apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="commune" ='`+valeur+`' AND "ANNEE"='`+props.annee+`'`
        }else{
          apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="commune" ='`+valeur+`'`
        }

        await axios.get(apipre, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
     
          setData(response.data.data)
      })

        }else{
           valeur = props.pref
        if(valeur.includes("'")){
            valeur = valeur.replace(/'/g, "''");
        }
        if (valeur ==='PREFECTURE DE RABAT'){
          valeur ='PRÉFECTURE DE RABAT'
      }
      
      var apicom;
      if(props.annee){
        apicom=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA=(datedepot LIKE '%03%')&ZOHO_CRITERIA="prefecture" ='`+valeur+`' AND "ANNEE"='`+props.annee+`'`
      }else{
        apicom=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA=(datedepot LIKE '%03%')&ZOHO_CRITERIA="prefecture" ='`+valeur+`'`
      }
        await axios.get(apicom, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
       
          setData(response.data.data)
      })

        }
        
  
      }else{
        var apiel;
        if(props.annee){
          apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="ANNEE"='`+props.annee+`'`
        }else{
          apiel=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Pourcentage des dossiers par type?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&ZOHO_ERROR_FORMAT=JSON`
        }
        await axios.get(apiel, {
          "Content-Type": "application/json; charset=utf-8"
      }).then((response) => {
       //console.log(response.data.data)
          setData(response.data.data)
      })
  
      }
       
    };
      getData();
  }, [props.pref,props.com,props.annee]);
var x=0
var y=0

if (Data.length !== 0){

  
x=Data.find(item => item.typePermis === "Certificat de conformité")

y=Data.find(item => item.typePermis === "Permis d'habiter")
var data = [
  { name: 'Certificat conformité', value: parseInt(x?x['DOSSIER_ID Nombre distinct']:'0')},
  { name: " Permis d'habiter", value: parseInt(y?y['DOSSIER_ID Nombre distinct']:'0') },
  ];
}
//console.log(data)

  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  return (
    <ResponsiveContainer width={350} height={222} >
    <PieChart>
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={165}
        cy={95}
        innerRadius={60}
        outerRadius={75}
        fill="#84d887"
        dataKey="value"
        onMouseEnter={onPieEnter}

      >
          {data?data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        )):data}
        </Pie>
    </PieChart>
    </ResponsiveContainer>
  );
};
