import React, { useState, useEffect } from 'react'
import { Chart as ChartJS, BarElement,CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2'
import axios from 'axios'


import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function LineChart(props) {



    const [checkboxes, setCheckboxes] = useState({
        dataset1: true,
        dataset2: true
    });
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        console.log(checked)
        setCheckboxes({ ...checkboxes, [name]: checked });
    };

    const [Data, setData] = useState([]);
    

    useEffect(() => {
        const monthNames = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];
        const getData = async () => {
            if (props.pref) {
                //console.log('L3aam',props.annee)
                var valeur = props.pref
                if (valeur.includes("'")) {
                    valeur = valeur.replace(/'/g, "''");
                }
                if (props.com) {
                    var valeur1 = props.com
                    if (valeur1.includes("'")) {
                        valeur1 = valeur1.replace(/'/g, "''");
                    }
                    var apipre;
                    if(props.annee){
                        apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Dossiers par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_DOSSIER"."ARRONDISSEMENT" ='` + valeur1 + `' AND "ANNEE"='`+props.annee+`'`;
                        console.log('test apipre',apipre)
                    }else{
                        apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier permis de construire e-déposé par catégoriee?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_DOSSIER"."ARRONDISSEMENT" ='` + valeur1 + `'`
                    }
                    await axios.get(apipre, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["DATEDEPOT"] !== "").map(item => {
                                const monthNumber = parseInt(item.DATEDEPOT.split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; // Month names array is 0-indexed
                                return { ...item, DATEDEPOT: monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["DATEDEPOT"] !== ""))
                        }
                    })
                } else {  
                    if(props.annee){
                        apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Dossiers par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_DOSSIER"."prefecture" ='` + valeur + `' AND "ANNEE"='`+props.annee+`'`;
                        console.log('test apipre',apipre)
                    }else{
                        apipre=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier permis de construire e-déposé par catégoriee?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_DOSSIER"."prefecture" ='` + valeur + `'`
                    }
                    await axios.get(apipre, {
                        "Content-Type": "application/json; charset=utf-8"
                    }).then((response) => {
                        if(props.annee){
                            setData(response.data.data.filter(item => item["DATEDEPOT"] !== "").map(item => {
                                const monthNumber = parseInt(item.DATEDEPOT.split('-')[0], 10);
                                const monthName = monthNames[monthNumber - 1]; 
                                return { ...item, DATEDEPOT: monthName };
                              }))
                        }else{
                            setData(response.data.data.filter(item => item["DATEDEPOT"] !== ""))
                        }
                    })

                }
            } else {
                var apichart;
                if(props.annee){
                    console.log('year is working')
                    apichart=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Dossiers par mois?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON&ZOHO_CRITERIA="FACT_DOSSIER"."ANNEE"='`+props.annee+`'`
                }else{
                    apichart=`https://analytics.karaz.org/api/Urbanisme-PC-PH-Service Rendu/Evolution de nombre de dossier permis de construire e-déposé par catégoriee?ZOHO_API_VERSION=1.0&KEY_VALUE_FORMAT=true&ZOHO_VALID_JSON=true&ZOHO_ACTION=export&ZOHO_OUTPUT_FORMAT=JSON&page=20&ZOHO_ERROR_FORMAT=JSON`
                }
                await axios.get(apichart, {
                      "Content-Type": "application/json; charset=utf-8"
                }).then((response) => {
                    if(props.annee){
                        setData(response.data.data.filter(item => item["DATEDEPOT"] !== "").map(item => {
                            const monthNumber = parseInt(item.DATEDEPOT.split('-')[0], 10);
                            const monthName = monthNames[monthNumber - 1]; 
                            return { ...item, DATEDEPOT: monthName };
                          }))
                    }else{
                        setData(response.data.data.filter(item => item["DATEDEPOT"] !== ""))
                    }
                })
            }
        };
        getData();
    }, [props.pref, props.com,props.annee]);
    console.log(Data.length)
   

    //console.log(startDate.getMonth())
    var data = {}
    if (Data.length === 0) {
        data = {
            labels: ['2019', '2020', '2021', '2022', '2023'],
            datasets: [{
                label: 'test1',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'rgba(194, 128, 162, 0.8)'
                ],
                borderWidth: 2,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset1,
            },
            {
                label: 'test2',
                data: [0, 0, 0, 0, 0],
                backgroundColor: [
                    'green'
                ],
                borderWidth: 3,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.2,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }
    function getUnique(array) {
        var uniqueArray = [];
        var i = 0
        // Loop through array values
        for (i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    if (Data.length !== 0) {
        var n = Data?.filter(x => x['CATEGORIE'] === 'Grands projets').map(x => x['DOSSIER_ID Nombre distinct'])
        var l = Data?.filter(x => x['CATEGORIE'] === "Petits projets").map(x => x['DOSSIER_ID Nombre distinct'])
        if (l.length !== n.length) {
            if (l.length > n.length) {
                n.unshift(0)
            } if (l.length < n.length) {
                l.unshift(0)
            }
        }
        data = {
            labels: getUnique(Data?.map(x => x['DATEDEPOT'])),
            datasets: [{
                label: "Nombre dossier",
                data: n,
                backgroundColor: [
                    'rgba(135,206,250,0.4)'
                ],
                borderWidth: 3,
                borderColor: 'rgba(135,206,250,0.4)',
                tension: 0.5,
                hidden: !checkboxes.dataset1,
            },
            {
                label: "Nombre dossier",
                data: l,
                backgroundColor: [
                    'rgba(120,150,250,0.4)'
                ],
                borderWidth: 3,
                borderColor: 'rgba(120,150,250,0.4)',
                tension: 0.5,
                hidden: !checkboxes.dataset2,

            }
            ]
        }
    }
    var options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false
            }
        },
        title: {
            display: true,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                title: {
                    display: true,
                    text: 'Date délivrance'
                }
            },
            y: {
                grid: {
                    display: true,
                },
                title: {
                    display: true,
                    text: 'Nombre dossiers'
                }
            }
        },
        legend: {
            labels: {
                fontSize: 26
            }
        }
    }
    return (
        <div class="bg-gray-100	 rounded-lg drop-shadow-md shadow-md shadow-blue-100/500  respodiv" style={{ display: "block", height: "100%" }}>
            <div className="ml-2 items-center pt-3 " style={{width:'100%'}}>
                <p class="font-sans md:font-serif font-medium" style={{paddingLeft:'15px'}}>Evolution annuelle du nombre de permis de construire délivrés par catégorie {props.annee?' en '+props.annee:''}</p>
                <div class="mb-2">
                </div>
            </div>
            <div class="flex flex-row mt-3" >
                <div class="ml-3" style={{ display: "block", height: "260px",width:'80%' }}>
                    {props.annee?( <Bar data={data} options={options} />):( <Line data={data} options={options} />)}
                   
                </div>
                
                <div class="flex flex-col justify-center items-center place-content-center pt-20" style={{ display: "block", width: "20%" }}>
                    <div >
                        <input
                            type="checkbox"
                            name="dataset1"
                            checked={checkboxes.dataset1}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc1'
            
                        />
                        <label class=""> Grand Projets </label>


                    </div>
                    <div >
                        <input
                            type="checkbox"
                            name="dataset2"
                            checked={checkboxes.dataset2}
                            onChange={handleCheckboxChange}
                            class="custom-checkbox"
                            id='pc2'
                           
                        />
                        <label class="mr-3"> Petit Projet  </label>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default LineChart